import * as React from 'react';
import axios from 'axios';
import withRouter from './withRouter';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { motion } from "framer-motion";
import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import './Post.css';

import CardInfo from './CardInfo';
import Comments from './Comments';
import CardsModule from './CardsModule';

class Post extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        };        
        
    };	

    
	componentDidMount() {
		
		//get our data
		this.loadData();

	}    


	//making sure that we refresh our content if url changes
	componentDidUpdate(prevProps) {

	  // Typical usage (don't forget to compare props):
	  if (this.props.params.postId !== prevProps.params.postId) {

		//the url changed, so let's reload our data
		this.loadData();
	    
	  }
	}


	//hent data asynkront
	async loadData() { 
		
		//define endpoint
		var apiEndpoint = "/photo/" + this.props.params.postId;

		//let's get the data
		try {

			//set base url
			const client = axios.create({baseURL: process.env.REACT_APP_API});

			//get data
			const responsePost = await client.get(apiEndpoint, { params: {
				photo_id: this.props.params.postId
			}});

			if (responsePost) { 			
				
				//opdater state var med data
			   	this.setState({ myPost: responsePost.data});
			   	
		   	}

		} catch (error) {
			this.setState({ myError: error});
			console.log(error.response.statusText);
		}
		
	}
    
    
    getImage = () => {
    	
    	if (this.state.myPost) {
	    	return (
	    		<img src={this.state.myPost.image.url} width="100%" alt={this.state.myPost.caption}/>
	    	)
    	} else {
			return (<Skeleton variant="rounded" width={"100%"} height={50} sx={{marginBottom: 2}} />)    		
   		}
    	
    	
   	}
   	
    getCaption = () => {
    	
    	if (this.state.myPost) {
	    	return (
	    		<p className="postTitle">{this.state.myPost.caption}</p>
	    	)
    	} else {
			return (<Skeleton variant="rounded" width={"100%"} height={20} sx={{marginBottom: 2}} />)    		
   		}
    	
   	}   	


    getTags = () => {
    	
   		//click handler
		const handleClick = () => {
		  console.info('You clicked the Chip.');
		};       		


		//check if we have any tags
		if (this.state.myPost.tags.length !== 0) {

	    	//loop and return tags, if first tag string length is > 0
	    	if (this.state.myPost.tags[0].length > 0) {
	    		
		    	return (
		    	
					this.state.myPost.tags.map ((content, key) => (
			    		<Chip label={content} onClick={handleClick} key={key}/>
					))
		    	
		    	)
	    	} else {
				return null    		
	   		}
			
		} else {
			
			return null			
		}


   		
   		
    	
   	}   

	getUser = () => {
		
    	if (this.state.myPost) {
    		
	    	return (
	    		<p>By <Link component={RouterLink} to={"/user/" + this.state.myPost._addonUser.id}>{this.state.myPost._addonUser.handle}</Link> </p>
	    	)
    	} else {
			return (<Skeleton variant="rounded" width={"100%"} height={20} sx={{marginBottom: 2}} />)    		
   		}		
	}


	//render
	render () { 
	
		if (this.state.myPost) {
			
			return (
			
				<Container maxWidth="xl" sx={{minHeight: "100vh"}}>
					<main>
					<Grid container columnSpacing={10} rowSpacing={4}>
						<Grid xs={12} sm={6}>
							<motion.div 
								initial={{ opacity: 0, y:+30 }}
							    animate={{ opacity: 1, y: 0 }}
							    transition={{ duration: 0.5 }}						
							>								
								{this.getImage()}
							</motion.div>					
						</Grid>
						<Grid xs={12} sm={6}>
						
							{this.getCaption()}
							{this.getUser()}
	
							<Stack direction="row" spacing={1} color="primary">
								{this.getTags()}
							</Stack>
							
							<Divider sx={{marginTop: "20px"}}/>
						
							<h4>Made with</h4>
							<CardInfo data={this.state.myPost} infoType="clays"/>
							<CardInfo data={this.state.myPost} infoType="glazes"/>
							<CardInfo data={this.state.myPost} infoType="firings"/>
							
							<Divider sx={{marginTop: "20px"}}/>

							<h4>Comments</h4>
							<Comments data={this.state.myPost}/>

						</Grid>
						
						
					</Grid>
					</main>
					
					<Stack direction="row" marginTop={8} spacing={1}>
						<CardsModule catType="user" catUserId={this.state.myPost._addonUser.id} catTitle={"More posts by " + this.state.myPost._addonUser.handle} catLayout="masonry" perPage={8}/>
					</Stack>
					
				</Container>
					
			);
		} else {
			return (
				<Container maxWidth="xl" sx={{minHeight: "100vh"}}>
					<main>
						<CircularProgress color="primary" />
					</main>
				</Container>
			);		
		}
	}

}

export default withRouter(Post);