import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';


class About extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        };        
    };	


	render () { 

		return (
			<main>

				<Container maxWidth="lg">

					<Grid container rowSpacing={1} columnSpacing={{sm:10, xs: 2}} marginTop={1}>
	
	
				    	<Grid xl={12} border={0}>
	
							<h1>CLAYCLAY is made for <strong>sharing and tracking</strong> the ceramic you produce.</h1>
							<p>You can use CLAYCLAY to easily store photos of your ceramics and share your creations with other ceramists - including info about the glazing, claytype and firingprocess you've used, so others can see how you've done</p>
				    	</Grid>
	
				    	<Grid xl={12} sm={12} md={12} lg={12} border={0}>
							<h2>THE SUMMARY</h2>
				    	</Grid>				  
				    	  	
				    	<Grid xs={12} sm={12} md={6} lg={6} xl={6} border={0}>
							
							<h3>We like</h3>
							<ul>
								<li>Uploads of nice and creative images of finished ceramics, which you've made yourself </li> 
								<li>Using the CLAYCLAY-website and app to discover new ways to make ceramics and be inspired by others creations</li>
							</ul>
							
							<h3>We don't like</h3>
							<ul>
								<li>Uploading irrelevant images, which doesn't show objects made in clay</li> 
								<li>Uploading images of ceramic objects which you haven't made yourself</li> 
								<li>Uploading ceramics that are clear copies of other ceramicists</li> 
								<li>Comments which can be construed as offensive, including spam or other content not related to ceramics</li> 
							</ul>

							<p>Any image or comment considered irrelevant, indecent or in any other way offensive by CLAYCLAY will be deleted without warning, and the user responsible for the content riscs permanent exclusion from CLAYCLAY.</p>


				    	</Grid>
				    	
				    	
				    	<Grid xs={12} sm={12} md={6} lg={6} xl={6} border={0}>
				    		
							<h3>DATAPROCESSING</h3>
							<ul>
								<li>We do not currently use cookies, but when you login we store your username, your email and your access token in your browsers local storage, to save you from having to log in every time you visit our site</li>
								<li>The pictures you upload, your user information (including your encrypted password) and your activity on the CLAYCLAY-website and app (when you like, comment or otherwise interact with the content) is stored on a server located in Germany</li>
								<li>We will <strong>never</strong> resell your user data</li>
								<li>We <strong>might</strong> use any or all uploaded images for generating custom image recognition models to improve the services of ClayClay. By signing up to ClayClay you agree to this usage. </li>
								<li>If you would like to request deletion of your account, including all the content you've uploaded, please contact us at user@clayclay.net</li>
							</ul>

				    	</Grid>
	
				    </Grid>				
				
				
					<Grid container rowSpacing={1} columnSpacing={{sm:10, xs: 2}} marginTop={1}>
	
				    	<Grid xl={12} sm={12} md={12} lg={12} border={0}>
							<h1>End User License Agreement</h1>
							
							<h3>1. Introduction</h3>
							<p>This End-User License Agreement ("EULA") is a legal agreement between you and CLAYCLAY. By installing or using CLAYCLAY, you agree to be bound by the terms of this EULA.</p>
							<p>We have assigned a data protection officer (DPO) as responsible for overseeing questions related to this EULA. If you have any questions or requests, please contact the DPO at user@clayclay.net</p>
							
							<h3>2. License Grant</h3>
							<p>Subject to the terms of this EULA, CLAYCLAY grants you a limited, non-exclusive, non-transferable license to use CLAYCLAY for personal, non-commercial purposes.</p>

							<h3>3. Termination</h3>
							<p>We reserve the right to terminate or suspend your license to use CLAYCLAY if you violate the terms of this EULA.</p>
							
							<h3>4. Data Collection and GDPR Compliance</h3>
							<p>By using CLAYCLAY, you acknowledge that we may collect and process personal data about you. Such processing is done in accordance with our Privacy Policy, which complies with the General Data Protection Regulation (GDPR). Visit www.clayclay.net/about to read our Privacy Policy.</p>
							
							<p>You have rights regarding your personal data under the GDPR. Please refer to our Privacy Policy for details on how we handle and protect your data and what rights you have.</p>
							
							<h3>5. Limitation of Liability</h3>
							<p>To the extent permitted by applicable law, CLAYCLAY will not be liable for any damages arising from or in connection with the use of CLAYCLAY.</p>
							
							<h3>6. Governing Law</h3>
							<p>This EULA is governed by the laws of Denmark.</p>
							
							<h3>7. Updates and Upgrades</h3>
							<p>We may provide updates or upgrades to CLAYCLAY, but they are not covered by this EULA. Separate terms and conditions may apply.</p>
							
							<h3>8. Consent to Electronic Communications</h3>
							<p>CLAYCLAY respects and follows the EU GDPR regulations, including options to unsubscribe from communications, and clear opt-in flows prior to electronic communications.</p>
							
							<h3>9. Third-Party Services</h3>
							<p>CLAYCLAY includes and integrates with third-party services. We're not responsible for these third-party services, and separate terms may apply.</p>
							
							<p>The following is a complete list of 3rd party services currently used by CLAYCLAY:</p>
							<ul>
							    <li>Xano.com, for storing data posted in the app and on the website.</li>
							    <li>One.com, for webhosting.</li>
							</ul>
							
							<p>Refer to our Privacy Policy for more information.</p>
							
							<h3>10. Entire Agreement</h3>
							<p>This EULA, together with our Privacy Policy and any other legal notices or additional terms and conditions or policies published by CLAYCLAY on the app, shall constitute the entire agreement between you and CLAYCLAY.</p>
							
							<h3>11. Amendments</h3>
							<p>We reserve the right to make changes to this EULA. We'll notify you of any changes in an appropriate way, for instance, by displaying a notice in the app or by sending an email.</p>
							
							<p>This EULA was last updated on August 1 Nov 2023.</p>
							<p>You can always contact us on user@clayclay.net with any questions or concerns regarding this End User Licence Agreement or our Privacy Policy.</p>

							<h3>12. Liability</h3>
							<p>Please note that use of any the information on clayclay.net or in the CLAYCLAY app is <strong>solely your own responsibility</strong>. We're not liable for any damages derived from using information provided by CLAYCLAY. </p>

							<p>We <strong>can not guarantee</strong> that the information we provide is always accurate, so please check the manufacturers website for information regarding glazes, firings and clays before using. </p>
							
				    	</Grid>				  
				    	  	

	
				    </Grid>						


					<Grid container rowSpacing={1} columnSpacing={{sm:10, xs: 2}} marginTop={1}>
	
				    	<Grid xl={12} sm={12} md={12} lg={12} border={0}>
							<h1>Privacy Policy</h1>
							
							<h3>1. Introduction & Contact‍</h3>
							<p>Welcome to CLAYCLAY. This privacy policy will inform you about how we handle and protect your personal data when using the CLAYCLAY app and website.</p>
							<p>We have assigned a data protection officer (DPO) as responsible for overseeing questions related to this privacy policy. If you have any questions or requests, please contact the DPO at user@clayclay.net</p>

							<h3>2. The data we collect about you and how we use it</h3>
							<p>The CLAYCLAY website ad app collects and store two types of personal data:</p>
							
								<ul>
									<li>User data - this includes your username, password, email-address and profile image. </li>
									<li>User content - this includes any images you upload to the platform and any associated data, e.g. comments and other interactions with the content</li>
								</ul>

							
							<h3>3. Disclosure of Your Personal Data</h3>
							<p>Your data is not shared with any 3rd party, nor sold to any 3rd party.</p>

							<h3>4. Data Security</h3>
							<p>We rely on the security of iOS and Xano to prevent your personal data from being accidentally lost, accessed, or used in an unauthorized way.</p>

							<h3>5. Data Retention</h3>
							<p>We will only retain your personal data for as long as necessary to fulfill the purposes for which it was collected. If your user has been inactive for 1 year, you will receive a notification via email that the content will be scheduled for deletion.</p>

							<h3>6. Your Legal Rights</h3>
							<p>You have rights under data protection laws in relation to your personal data. These include the right to request access, correction, erasure, restriction, or transfer of your personal data. Contact us on user@clayclay.net if you have any questions or requests in this regard.</p>

							<h3>7. Third-Party Links</h3>
							<p>The app does not include links to third-party websites, plugins, or applications.</p>

							<h3>8. Cookies</h3>
							<p>Our website does not use cookies.</p>

							<h3>9. Changes to the Privacy Policy</h3>
							<p>This version was last updated on November 1st 2023. </p>
							<p>You can always contact us on user@clayclay.net with any questions or concerns regarding this Privacy Policy or our End User Licence Agreement.</p>
							
				    	</Grid>				  
				    	  	

	
				    </Grid>						
				
				
				
				</Container>
				
			</main>

		);
  	}

}

export default About;