import * as React from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddDesign from './AddDesign';
import NotFound from './NotFound';
import AddFiring from './AddFiring';
import AddGlaze from './AddGlaze';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';


class AddTabs extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        	openTab: 0
        };        
        
    };	
	
	
	//change tab onclick
	switchTab = (myTab) => {
	
		//set open tab 
	   	this.setState({ openTab: myTab});
		
	}

	//return boolean visibility for tab
	getTabVisibility = (myTab) => {
		
		if (this.state.openTab === myTab) {
			return false	
		} else {
			return true			
		}

	}	
	

	render () { 
		
		if (localStorage.localToken) {

			return (
				<Container maxWidth="md">
	
					<Grid container spacing={0} padding={1} marginBottom={0}>
	
				    	<Grid xs={12} paddingLeft={2}>
					        <Tabs value={this.state.openTab} sx={{marginBottom: "40px", borderBottom: 1, borderColor: 'divider'}} aria-label="Tabs">
					          <Tab label="Ceramic"  onClick={() => {this.switchTab(0)}}/>
					          <Tab label="Firing"  onClick={() => {this.switchTab(1)}}/>
					          <Tab label="Glazing"  onClick={() => {this.switchTab(2)}}/>
					        </Tabs>	
				    	</Grid>
				    	
				    </Grid>				
	
			        <div role="tabpanel" id="tab0" hidden={this.getTabVisibility(0)} index={0}>
						  <AddDesign />
					</div>
	
			        <div role="tabpanel" id="tab1" hidden={this.getTabVisibility(1)} index={1}>
						  <AddFiring randomProp={Math.random()}/>
					</div>
					
			        <div role="tabpanel" id="tab2" hidden={this.getTabVisibility(2)} index={2}>
						  <AddGlaze />
					</div>
					
				</Container>	
			);


		} else {
		
			return (
			
				<Container maxWidth="md"> 
					<NotFound />
				</Container>
			
			); 
			
		}


  	}

}

export default AddTabs;

					
			
