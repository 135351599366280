import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import './CardInfo.css';


//icons
import iconClay from './img/icon_clay@1x.svg';
import iconGlaze from './img/icon_glaze@1x.svg';
import iconFire from './img/icon_fire@1x.svg';
import Divider from '@mui/material/Divider';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';


class CardInfo extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        	returnObj:null
        };        
    
    };


	//do our stuff on mount
	componentDidMount() {
		this.beginLoad();
	}
	
	
	//making sure that we refresh our content if our data changes
	componentDidUpdate(prevProps) {

		// Typical usage (don't forget to compare props):
		if (this.props.data !== prevProps.data) {
			this.beginLoad();
		}

	}
	
	
	//let's load our data
	beginLoad = () => {

		//choose what data to return
		if (this.props.infoType === "glazes") {
			this.setState({returnObj: this.getGlaze()});
		} else if (this.props.infoType === "clays") {
			this.setState({returnObj: this.getClays()});
		} else if (this.props.infoType === "firings") {
			this.setState({returnObj: this.getFiring()});
		}
		
	}	
	


	//return all glazes	
	getGlaze = () => {

		//get glaze var
		const myGlazes = this.props.data.glazes;

		return (
			myGlazes.map (glazes =>(
				glazes._addonGlazes.map (glaze =>(
					<Grid container spacing={0} padding={1} key={glaze.id} className="detailsItem">
						<Grid xs={1} className="detailsIcon"><img src={iconGlaze} alt="Glaze" className="detailsGraphic" /></Grid>
						<Grid xs={11} paddingLeft={1} className="detailsText">
							<Link component={RouterLink} to={"/search/glaze:" + glaze.id}>{glaze._addonCompanies.company_name} {glaze.glaze_number}, {glaze._addonColors.name}, {glaze.transparency}</Link>
						</Grid>
						<Divider variant="middle" />
					</Grid>   					
				))		
			))		
		)
		
	}




	//return all firings	
	getFiring = () => {

		//get glaze var
		const myFirings = this.props.data.firings;
		var tmpId = 0;
			
			
		//return if we have a bisque or glaze firing	
		function getBisque (test, title) {
			
			if (test) {
				return (
					<Tooltip title={title} placement="top">
						<div>Bisque firing</div>
					</Tooltip>	
				)
			} else {
				return (
					<Tooltip title={title} placement="top">
						<div>Glaze firing</div>
					</Tooltip>	
				)
			}
						
		}	
		
		return (

			myFirings.map (firings =>(
				firings._addonFiringsv2.map (firing =>(
					<Grid container spacing={0} padding={1} key={firings.firingsv2_id} border={0} className="detailsItem">
						<Grid xs={1} className="detailsIcon"><img src={iconFire} alt="Firing" className="detailsGraphic"/></Grid>
						<Grid xs={11} paddingLeft={1} className="detailsText">
							<Link component={RouterLink} to={"/search/firing:" + firing.id}>{getBisque(firing.bisque, firing.title)}</Link>
						</Grid>

						{firing.curve.map (curve => 
							(
								<Grid container xs={12} spacing={0} padding={0} key={tmpId++} className="detailsItem">
									<Grid xs={1} className="detailsIcon"></Grid>
									<Grid xs={11} className="detailsTextList">
										<ul>
											<li className="c5text">{curve.start_temp} to {curve.end_temp}{firing.temp_scale}, {curve.increment_deg}{firing.temp_scale} in {curve.increment_mins} minutes, {curve.hold_time} min. dwell time</li>
										</ul>	
									</Grid>
								</Grid>							
							))
						}							
						
					</Grid>						
					
				))		
			))		
				
		)
		
	}


	//return all clays	
	getClays = () => {

		//get glaze var
		const myClays = this.props.data.clays;
		
		return (
			myClays.map (clays =>(
				clays._addonClays.map (clay =>(
					<Grid container spacing={0} padding={1} key={clay.id} className="detailsItem">
						<Grid xs={1} className="detailsIcon"><img src={iconClay} alt="Clay" className="detailsGraphic" /></Grid>
						<Grid xs={11} paddingLeft={1} className="detailsText"><Link component={RouterLink} to={"/search/clay:" + clay.id}>{clay._addonCompanies.company_name} {clay.product_number}, {clay.product_name}</Link></Grid>
					</Grid>   					
				))		
			))		
		)
		
	}



	render () { 
		
		return (
			this.state.returnObj
		)

  	}

}

export default CardInfo;