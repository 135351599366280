import * as React from 'react';
import axios from 'axios';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputFilter from './InputFilter';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import Resizer from "react-image-file-resizer";


class EditDesign extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        	postId: 113,
        	postData: null,
        	selectedGlazes: [],
        	selectedClays: [],
        	selectedFirings: [],
        	imgUrl: "",
			fNotes: null, 
			fTags: null,
			showLoader: false,
			redirect: null       	
        };        
        
   		//bind vores async funktioner
   		this.doLocalLoad = this.doLocalLoad.bind(this);
   		this.doUpload = this.doUpload.bind(this);
   		this.doUploadFinish = this.doUploadFinish.bind(this);
   		this.getPost = this.getPost.bind(this);
   		this.updatePost = this.updatePost.bind(this);
    };	
	
	
	componentDidMount () {
		
		//load our initial post data
		this.getPost();
		
	}
	
	
	async getPost () {
	
		//set base url
		const client = axios.create({baseURL: process.env.REACT_APP_API});			
			
		try {

			const postResponse = await client.get(("/getpost/" + this.state.postId));	

			if (postResponse) {
				this.setState({ postData: postResponse.data});
				this.setState({imgUrl: postResponse.data.image.url});
				console.log(this.state.postData);
				
		    	//this.setState({imgObj: files[0]});
		    	//this.setState({imgUrl: mitImg.src});
				
			}

		} catch (error) {
			console.log(error);
		} 	
			
	}
	
	
	
	
	async updatePost () {

		//check if we have local storage		
		if (typeof(Storage) !== "undefined") {
			
			if (this.state.postData) {
			
				try {
		
					//set our auth token		
					axios.defaults.headers.common['Authorization'] = localStorage.localToken;				
					
					//set base url
					const client = axios.create({baseURL: process.env.REACT_APP_API});		
		
					//post our update
					const postResponse = await client.post(("/photo/" + this.state.postData.id), {
						"photo_id": this.state.postData.id,
						"image": this.state.postData.image,
						"caption": this.state.postData.caption,
						"tags": this.state.postData.tags,
						"glazes": this.state.postData.glazes,
						"clays": this.state.postData.clays,
						"firings": this.state.postData.firings
					});	
					
					
					if (postResponse) {
						
						//hide our spinner
						this.setState({ showLoader: false});
						
						//set our redirect url
						const redirectUrl = "/post/" + this.state.postData.id;
						this.setState({redirect: redirectUrl});
						
					}
		
				} catch (error) {
					console.log(error);
				} 
			
			}		
		
		}			
			
	}	
	
	
	//update state with notes
   	fHandler = (e) => {
   		this.setState({fNotes: e.target.value});
	}
	
	
	//update state with combobox data
   	myInputFilterHandler = (myEndpoint, myVals) => {
   		
   		//create a tmp array
   		var tmpArray = [];
   		
   		if (myEndpoint === "/clays") {

			//fill our array with filtered data 
			tmpArray = myVals.map(({clays_id, value} ) => {
				return ({"clays_id" : value})
			})
			
   			//update state with converted array
	   		this.setState({ selectedClays: tmpArray});
	   		
   		} else if (myEndpoint === "/glazes") { 

			//fill our array with filtered data 
			tmpArray = myVals.map(({glazes_id, value} ) => {return ({"glazes_id" : value})})
   			
   			//update state with converted array
	   		this.setState({ selectedGlazes: tmpArray});
	   		
   		} else if (myEndpoint === "/firingsv2") {
   			
			//fill our array with filtered data 
			tmpArray = myVals.map(({firings_id, value} ) => {return ({"firingsv2_id" : value})})
   			
   			//update state with converted array
	   		this.setState({ selectedFirings: tmpArray});
	   		
   		}
   		
	}	
   	
    
	//load local image file into state var ASYNC
	async doLocalLoad (event) {
		
		//get the selected files
		var files = event.target.files; // FileList object
		
		//create an object url from the first file
		const mitImg = document.createElement("img");
		mitImg.src = URL.createObjectURL(files[0]);
		mitImg.onload = function() {
		  URL.revokeObjectURL(this.src);
		}
		
		//update our state with image url and image data
    	this.setState({imgObj: files[0]});
    	this.setState({imgUrl: mitImg.src});
    	
	}     
	
	
	//start upload
	async doUpload () {
		
		//bind our this to be able to setstate in postResponse
		var that = this;		
		
		//show our spinner to let user know we're processing
		this.setState({ showLoader: true});
		
		//let's resize our image before we upload
		try {
		  Resizer.imageFileResizer(
		  	that.state.imgObj, 
		  	1400, 
		  	1400, 
		  	"JPEG", 
		  	90, 
		  	0, 
		  	(uri) => {
		  		//resize was a succes, now let's proceed to upload
		  		that.doUploadFinish(uri);
		    },
		    "base64",
		    600,
		    600
		  );
		} catch (err) {
		  console.log(err);
		}		

	}
	
	
	//do final upload after image resize
	async doUploadFinish (resizedBase64) {
		
		//bind our this to be able to use setState in postResponse
		var that = this;		

		//save our state in consts for some crazy reason
		const tmpGlazes = this.state.selectedGlazes;
		const tmpFirings = this.state.selectedFirings;
		const tmpClays = this.state.selectedClays;
		const tmpNotes = this.state.fNotes;		
		
		//set base url
		const client = axios.create({baseURL: process.env.REACT_APP_API});			
		
		try {
			
			const imgUploadResponse = await client.post("/upload/image", {
				"content": resizedBase64
			});
			
			
			if (imgUploadResponse) {
				
				try {

					const postResponse = await client.post("/addPost", {
						"image": imgUploadResponse.data,
						"caption": tmpNotes,
						"user_id": localStorage.localUserId,
						"glazes": tmpGlazes,
						"clays": tmpClays,
						"firings": tmpFirings
					});	

					if (postResponse) {
						
						//hide our spinner
						that.setState({ showLoader: false});
						
						//set our redirect url
						const redirectUrl = "/post/" + postResponse.data.photo_1.id;
						that.setState({redirect: redirectUrl});	
						
					}

				} catch (error) {
					console.log(error);
				} 	
				
			}
					
		} catch (error) {
			console.log(error);
		}


	}	


	//return submit button
	getSubmit = () => {
		
    	if (this.state.showLoader) {
			return (<Skeleton variant="rounded" width={"100%"} height={40} sx={{marginBottom: 2}} />)    		
    	} else {
	    	return (<Button size="large" variant="contained" sx={{width: "100%"}} onClick={this.updatePost}>Update info</Button>)
   		}		
	}
	
	
	resetRedirect = () => {
		this.setState({redirect: null});			
		this.setState({imgUrl: null});	
		this.setState({showLoader: null});	
	}
	
	
	getVal = (myVal) => {
		
		if (this.state.postData) {
			return (this.state.postData.caption)
		} else {
			return null;			
		}
	}
	
    
	render () { 

		//react router redirect handling
		if (this.state.redirect) {

			return (
					<main>
					
						<Container maxWidth="md"> 
	
					    	<h1>Your ceramic has been updated!</h1>
					    	<p><Link component={RouterLink} to={this.state.redirect}>Click here to see</Link></p>
					    	<p><Link component={RouterLink} onClick={this.resetRedirect}>Add new ceramic</Link></p>
					
						</Container>
					</main>				
			)
		} else {
			//normal return
			return (
	
					<main>
					
						<Container maxWidth="md"> 
	
							<Button aria-label="Upload picture" component="label" variant="outlined" onChange={this.doLocalLoad} sx={{display:"flex", flexDirection: "column", width: "100%", minHeight:"300px", marginBottom: 2}}>
								<input hidden accept="image/jpg, image/jpeg, image/png, image/gif, image/webp" type="file" required/>
								<img src={this.state.imgUrl} style={{width:"100%"}} alt="" />
								Choose image
							</Button>	
					    
							<Stack spacing={2} direction="column" sx={{marginBottom:"20px"}}>
						    	<InputFilter endpoint="/clays" inputId="comboClays" title="Choose claytypes" onInputUpdate={this.myInputFilterHandler} randomProp={Math.random()} inputSelection={[]}/>
						    	<InputFilter endpoint="/glazes" inputId="comboGlazes" title="Choose glazes" onInputUpdate={this.myInputFilterHandler} randomProp={Math.random()} inputSelection={[]}/>							
						    	<InputFilter endpoint="/firingsv2" inputId="comboFirings" title="Choose firings" onInputUpdate={this.myInputFilterHandler} randomProp={Math.random()} inputSelection={[]}/>
							</Stack>
	
							<TextField id="fNotes" defaultValue={this.getVal("caption")} style={{width:"100%", marginBottom:20}} multiline variant="outlined" onChange={this.fHandler}/>
							
							{this.getSubmit()}
							
					    	<p>Please make sure to only upload images of your own ceramics. Any images deemed irrelevant or in violation of our <Link component={RouterLink} to="/about">Guidelines</Link> will be removed without prior notice.</p>
					
						</Container>
					</main>					
					
			);
		}
  	}

}

export default EditDesign;