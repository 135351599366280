import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import './Footer.css';


class Footer extends React.Component {

    constructor (props) {
        super(props);
        this.state = {

        };
   		
        
    };	

	//logout user
	doLogout = () => {

		//check if we have local storage		
		if (typeof(Storage) !== "undefined") {
		  	
		  	//check if we have a token
			if (localStorage.localToken) {

				//let's remove our token and any user data
				localStorage.removeItem("localToken");
				localStorage.removeItem("localUserName");
				localStorage.removeItem("localUserEmail");
				localStorage.removeItem("localUserId");

				//go to frontpage now that we're logged out 
				window.location.assign(window.location.origin); 	
							
			} else {
				alert("NO TOKEN FOUND");				
			}

		}		
				
	}
	
	showUser = () => {

		//check if we have local storage		
		if (typeof(Storage) !== "undefined") {
		  	
		  	//check if we have a token
			if (localStorage.localToken) {
				
				return (
					<Grid container columnSpacing={2} rowSpacing={2} marginTop={2}>
						<Grid xs={12} sx={{textAlign: "center"}}>
							<p>You're logged in as <Link component={RouterLink} to="/user">{localStorage.localUserName}</Link></p>
							<Button variant="outlined" onClick={this.doLogout} size="small">Logout</Button>
						</Grid>
					</Grid>						
				)				
				
			} 			
			
		} 
		
	}	
	


	render () { 

		return (
			<main className="footer c1text">
				<Container maxWidth="xl">

					{this.showUser()}

					<Grid container columnSpacing={2} rowSpacing={2} marginTop={4}>
						<Grid xs={12} sx={{textAlign: "center"}}>
							Copyright: CLAYCLAY 2023, v1.0
						</Grid>

						<Grid xs={12} sx={{textAlign: "center"}}>
							<Link component={RouterLink} to="/about">Our Guidelines</Link> 
						</Grid>

					</Grid>						

					
				</Container>
			</main>
		
		
			
		);	
  	}

}

export default Footer;