import * as React from 'react';
import axios from 'axios';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { motion } from "framer-motion";

class SignIn extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        	myResponse: null,
        	fEmail : null,
        	fPass : null 
        };
   		
   		//bind vores async funktioner
   		this.loginUser = this.loginUser.bind(this);
   		this.doLoginToggle = this.doLoginToggle.bind(this);
        
    };	



	//login bruger asynkront
	async loginUser() { 
		
		//set base url
		const client = axios.create({baseURL: process.env.REACT_APP_API});			

		//login
		const response = await client.post("/auth/login", { 
			email: this.state.fEmail,
			password: this.state.fPass
		}).catch(error => {
			this.setState({ myError: error});
		});
		
		if (typeof(Storage) !== "undefined") {
			
			//let's save user token to local storage
			localStorage.setItem("localToken", response.data.authToken);

			//reload the page now that we've updated our token 
			window.location.assign(window.location.origin); 		  
		  
		} else {
		  // Sorry! No Web Storage support..
		}
	}


	//return login page if we're not already logged in
	doLoginToggle = () => {
		
		
		return (
			<main>
				<Container maxWidth="xs" sx={{minHeight: "100vh"}}> 
					<h1>Login</h1>
					<motion.div 
						initial={{ opacity: 0, y:-30 }}
					    animate={{ opacity: 1, y: 0 }}
					    transition={{ duration: 0.5 }}						
					>						
						<Stack direction="column" spacing={2}>
								<TextField label="Email" type="email" id="fEmail" onChange={this.updateState} />	
								<TextField label="Password"  id="fPass" type="password"  onChange={this.updateState} />	
								<Button size="large" variant="contained" onClick={this.loginUser}>Login</Button>
						</Stack>
					</motion.div>										
					
				</Container>
			</main>			
		)				

	
	}
	
	//update state with form input values
	updateState = (event) => {
    	this.setState({[event.target.id] : event.target.value});
	}
	


	render () { 

		return (
			<>
				{this.doLoginToggle()}
			</>
		);	
  	}

}

export default SignIn;