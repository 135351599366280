import * as React from 'react';
import axios from 'axios';
import './CardsModule.css';

import Grid from '@mui/material/Unstable_Grid2';
import Cards from './Cards';
import Pagination from '@mui/material/Pagination';	
import ImageList from '@mui/material/ImageList';
import CircularProgress from '@mui/material/CircularProgress';


class CardsModule extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        	myPost: null,
        	myError: null,
        	currentPage: 1, 
        	totalPages: 1, 
        };        
    };	


	componentDidMount() {
	    
		//load api data
	    this.loadData();
	    
	}
	
	
	//return our pagination widget
	doPagination = () => {
		
		return (
			<Pagination count={this.state.totalPages} page={this.state.currentPage} color="primary" onChange={this.switchPage} />		
		)
		
	}
	
	
	//increment page
	switchPage = (event: React.ChangeEvent<unknown>, value: number) => {
			
		//check if we have data
		if (this.state.myPost) {
				
				
			//update our state 
		   	this.setState({ currentPage: value});
			
			//load page				
			this.loadData(value);
				
		}
		
	}

	
	//override db category title if component prop is set
	getCatTitle = () => {

		if (this.props.catTitle) {
			return (this.props.catTitle)
		} else {
			return null
		}
				
	}


	//hent data asynkront
	async loadData(pageNumber) { 
		
		var apiEndpoint;
		var tmpUserId;

	
		//set endpoint based on prop
		if (this.props.catType === "user") {
			
			//check if we're getting a userid as prop
			if (this.props.catUserId) {
				
				//we have a prop userid, so let's load data from that id
				apiEndpoint = "/user/" + this.props.catUserId + "/photos";
				tmpUserId = this.props.catUserId;
				
			} else {
				
				//no prop with userid, so let's just load data from the logged in user
				apiEndpoint = "/user/" + localStorage.localUserId + "/photos";
				tmpUserId = localStorage.localUserId;

			}
			
		} else {
			
			//set our default		
			apiEndpoint = "/photo";
						
		} 

		
		//check if we have local storage		
		if (typeof(Storage) !== "undefined") {
	  	
			//let's get the data
			try {

				//set our auth token		
				axios.defaults.headers.common['Authorization'] = localStorage.localToken;				

				//set base url
				const client = axios.create({baseURL: process.env.REACT_APP_API});
	
				//get data
				const responseCards = await client.get(apiEndpoint, { params: {
					user_id: tmpUserId,
					search: {
						"page": pageNumber, 
						"per_page": this.props.perPage 
					} 
				}});
	
				if (responseCards) { 			
					
					//opdater state var med data
				   	this.setState({ myPost: responseCards.data});
				   	this.setState({ totalPages: responseCards.data.pageTotal});
				   	this.setState({ currentPage: responseCards.data.curPage});
				   	
			   	}
	
			} catch (error) {
				
				this.setState({ myError: error});
				//console.log(error.response.statusText);
			}

			
		}
		
	}

	
	
	//loop available cards
	loopData = (myPost, myLayout) => {
		
		
		//check if we have api response
		if (myPost) {

			//check if we actually have some images
			if (myPost.items.length > 0) {
			
				//pick a layout
				if (myLayout === "cards") {
					
					return (		
						myPost.items.map (content =>(
							<Cards data={content} to={"/post/" + content.id} cardType={myLayout} key={content.id} catType={this.props.catType}/>
						))
					)	
				
				} else if (myLayout === "masonry") {
					
					return (
				      <ImageList variant="masonry" gap={4} sx={{columnCount: {xs: '2 !important', sm: '2 !important', md: '3 !important', lg: '4 !important', xl: '4 !important'}}}>
				        {myPost.items.map((content) => (
						  <Cards data={content} cardType={myLayout} key={content.id} catType={this.props.catType}/>
				        ))}
				      </ImageList>	
				    )			
					
				} else {
					return (
						<>Sorry, no image type was defined. This is an internal error, that you can't do anything about. Sorry.</>
					)				
				}
				
			} 						
			
		} 
		
	}


	render () {

		//do we have api response
		if (this.state.myPost) {
			
			//if there are any items
			if (this.state.myPost.itemsReceived !== 0) {

				return (
					<div className="cardWrapper">
					
						<Grid container columnSpacing={2} rowSpacing={2}>
							<Grid xs={9} sm={12} md={6} lg={6} xl={6}><h3>{this.getCatTitle()}</h3></Grid>
						</Grid>	
		
						<Grid container columnSpacing={2} rowSpacing={2}>
							{this.loopData(this.state.myPost, this.props.catLayout)}
						</Grid>	
	
						<Grid container columnSpacing={2} rowSpacing={2} marginTop={4}>
							<Grid xs={12} sx={{display:"flex", justifyContent:"center", alignItems: "center"}}>{this.doPagination()}</Grid>
						</Grid>						
						
					</div>
				)
					
			} else {
				return (
					<Grid container columnSpacing={2} rowSpacing={2} marginTop={4}>
						<Grid xs={12} sx={{display:"flex", flexDirection: "column", justifyContent:"center", alignItems: "center"}}>
							<h4>No photos have been added here yet</h4>
						</Grid>
					</Grid>							
				)
			}
				
		} else {
	
	
			//return loading indicator
			return (
			
				<div className="cardWrapper">
					<Grid container columnSpacing={2} rowSpacing={2}>
						<Grid xs={12}><h3>{this.getCatTitle()}</h3></Grid>
					</Grid>				

					<div className="progressWrapper">
						<CircularProgress color="primary" />
					</div>
				</div>
			);		
		}
					
  	}

}

export default CardsModule;