import * as React from 'react';
import axios from 'axios';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import './AddFiring.css';


class AddFiring extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        	fNotes: "",
			fBurnType: true, 
			fTitle: "", 
			fChoice: null,
			fCurves: [], 
			fCurveTemplate:  {
				"end_temp": 0, 
				"hold_time": 0,
				"increment_deg": 0, 
				"increment_mins": 60, 
				"start_temp": 0				
			}
        };        
        
        //bind our async functions
   		this.doUpload = this.doUpload.bind(this);
   		this.getFirePoint = this.getFirePoint.bind(this);

    };	
	
	
	//mount our component
	componentDidMount() {

		//load our data
		this.loadData();

	}   
	
	//making sure that we refresh our data if random prop changes
	componentDidUpdate(prevProps) {

	  // Typical usage (don't forget to compare props):
	  if (this.props.randomProp !== prevProps.randomProp) {

		//let's reload our data
		this.loadData();
	    
	  }
	}		
	
	
	/********* MANAGE DATA *********/	
	
	//API hent data asynkront
	async loadData() { 
		
		var apiEndpoint = "/firingsByUser";		

		//check if we have local storage		
		if (typeof(Storage) !== "undefined") {
	  	
			//let's get the data
			try {

				//set our auth token		
				axios.defaults.headers.common['Authorization'] = localStorage.localToken;				

				//set base url
				const client = axios.create({baseURL: process.env.REACT_APP_API});
	
				//get data
				const response = await client.get(apiEndpoint, { params: {
					user_id: localStorage.localUserId
				}});
	
				if (response) {
					
					//map list of firings for the selection box
					var tmpList = response.data.map (item =>(
						{label: item.title, id: item.id}
					))
					
					//update state with mapped firings list
					this.setState({ myFiringsList: tmpList});

					//update state with entire response
					this.setState({ myFirings: response.data});
					
			   	}
	
			} catch (error) {
				this.setState({ myError: error});
				console.log(error);
			}
			
		}
		
	}
	
	
	//API save data async
	async doUpload() {
		
		//get the users choice 
		const myDataChoice = this.state.fChoice;
		var myDataToApi = null;
		var apiEndpoint = null;

		//check if we're logged in
		if (typeof(Storage) !== "undefined") {

			//user has loaded an existing firing
			if (myDataChoice) {
	
				//UPDATE EXISTING FIRING
				
				//create new object with changed data  
				const myDataFinal = myDataChoice.map((item) => {
					return {...item, curve: this.state.fCurves, bisque: this.state.fBurnType, title: this.state.fTitle, noter: this.state.fNotes};
				});			
				
				
				//save our data in final var
				myDataToApi = myDataFinal[0];
				
				//create our endpoint
				apiEndpoint = "/firingsv2/" + myDataToApi.id
			
			} else {
	
				//ADD NEW FIRING
					
				//check if user has entered a title
				if (this.state.fTitle) {

					//make var with data 
					myDataToApi = {
							bisque: this.state.fBurnType, 
							curve: this.state.fCurves,
							noter: this.state.fNotes,
							temp_scale: "C",
							title: this.state.fTitle,
							user_id: localStorage.getItem('localUserId')
					}
					
					apiEndpoint = "/firingsv2"
				
				} else {
					alert("Please enter a title before saving");					
				}
					
			}
			
			
	  	
			//let's post the data
			try {
				
				//set our auth token		
				axios.defaults.headers.common['Authorization'] = localStorage.localToken;				

				//set base url
				const client = axios.create({baseURL: process.env.REACT_APP_API});
	
				//get data
				const response = await client.post(apiEndpoint, myDataToApi);
	
				if (response) {
					//console.log(response);
					
					if (response.status === 200) {

						//reload our data
						this.loadData();						
						
						//inform the user
						alert("Your firing has been saved!");	
					} 
			   	}
	
			} catch (error) {
				this.setState({ myError: error});
				console.log(error);
			}			
			

		} else {
			alert("You need to be logged in to be able to create a firing");				
		}
		


		
	}		


	/********* EVENT HANDLERS *********/

	
	//update state on burntype dropdown change
  	changeHandler = (event) => {

		//update state with converted array
   		this.setState({fBurnType: event.target.value});
		
 	}


	//handles dropdown input to load an existing firing 
  	changeHandlerFiring = (myEvent, myVals) => {

		//first, let's reset our curves	   		
   		this.setState({fCurves: []});	   		

		if (myVals) { 		

			//create var with our choice (filtered from users entire list of firings)
			const myChoice = this.state.myFirings.filter(firing => firing.id === myVals.id);
	   		this.setState({fChoice: myChoice});	   		
	   		this.setState({fNotes: myChoice[0].noter});	
	   		this.setState({fTitle: myChoice[0].title});	
	   		
			//create var with the selected firing curve and update the state with it
			const myChoiceCurve = myChoice[0].curve;
	   		this.setState({fCurves: myChoiceCurve});	

	   		
   		} else {

			//user has cleared the choice, so let's reset our state 
	   		this.setState({fChoice: null});	   		

		}
		
 	}
 	

	//handles updates of meta input
	myUpdater = (event) => {
		
		//update our state vars depending on what event we get 		
		if (event.target.id === "id_titel") {this.setState({fTitle: event.target.value});} 
 		else if (event.target.id === "id_notes") {this.setState({fNotes: event.target.value});} 
		
	}
	
	
	
	/********* CURVE HANDLING  *********/
	
	//loop our fCurves array 
	getAllCurves = () => {
		
		//OK

		var x = 0;

		return (
		
			this.state.fCurves.map (curve =>(
				<Grid xs={12} sm={6} md={4} lg={4} xl={3} key={x++}>
					{this.getFirePoint(x)}
				</Grid>
			))
		)
		
	}
		
	
	//add a new firepoint
	addCurve = () => {
		
		//temp vars to hold our state arrays
		var tmpfCurves = this.state.fCurves;
		var tmpTemplate = this.state.fCurveTemplate;
		
		//create empty array
		var fuckArrays = [];
		
		//update our array
		fuckArrays = [...tmpfCurves, tmpTemplate];

		//update state with array
		this.setState({fCurves: fuckArrays})
		
	}


	//delete firepoint from array
	deleteCurve = (myKey) => {

		//create a filtered array without our myKey item
		const filteredArray = this.state.fCurves.filter((_, i) => i !== myKey);
		
		//update state with new array
		this.setState({fCurves: filteredArray})
		
	}
		
		
	//handles input from curve textfields (temp, holdtime, etc)
	getFirePointHandler = (myKey, myId, event) => {

		const myObj = this.state.fCurves;
		const myVal = event.target.value;
		let valid = false;
		valid = !isNaN(+myVal);
		
		if (valid && myVal) {

			if (myVal.length < 5) {
				
				//create new object  
				const newObj = myObj.map((item, index) => {
		
					//change value if we have the right index
					if (index === myKey) {
						
						if (myId === "end_temp") {return {...item, end_temp: myVal};}
						if (myId === "start_temp") {return {...item, start_temp: myVal};}
						if (myId === "hold_time") {return {...item, hold_time: myVal};}
						if (myId === "increment_deg") {return {...item, increment_deg: myVal};}
						
					} else {
						
						//not the right index, so let's return unchanged item
						return {...item}			
					}
					
					return {}
			
				});		
		
				this.setState({fCurves: newObj})
			
			}
			
		} 

	}			
		

	
	/********* SECTIONS *********/
	

	//title text input
	getFirePointMeta = () => {
		
		//if we have the API response...
		if (this.state.myFiringsList) {

			return (
			
				<Grid container columnSpacing={3} rowSpacing={3}>
					<Grid xl={12} xs={12}>
						<h3>Add new firing</h3>

						<Autocomplete disablePortal id="id_storedTitles"
						  options={this.state.myFiringsList}
						  fullWidth
						  renderInput={(params) => <TextField {...params} label="Load a saved firing" />}
						  onChange={this.changeHandlerFiring}
						/>	
					</Grid>

					<Grid xl={12} xs={12}>
				        <TextField id="id_titel" label="Title" value={this.state.fTitle} fullWidth onChange={this.myUpdater}/>					
					</Grid>				


					<Grid xl={12} xs={12}>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Type</InputLabel>
							<Select id="id_burn_type" label="Type" value={this.state.fBurnType} fullWidth onChange={this.changeHandler}>
								<MenuItem value={true}>Bisque firing</MenuItem>
								<MenuItem value={false}>Glaze firing</MenuItem>
							</Select>				
						</FormControl>  
	
					</Grid>

					<Grid xl={12} xs={12} marginBottom={3}>
				        <TextField id="id_notes" label="Additional notes about the firing (optional)" value={this.state.fNotes} fullWidth onChange={this.myUpdater}/>					
					</Grid>				

				</Grid>			
				
			
			)
						
		} else {
			
			return (

				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid xl={8} xs={12}>
						<Skeleton variant="rounded" width={"100%"} height={40} />
					</Grid>
					<Grid xl={4}>
						<Skeleton variant="rounded" width={"100%"} height={40} />
					</Grid>
					<Grid xl={12} marginBottom={3}>
						<Skeleton variant="rounded" width={"100%"} height={40} />
					</Grid>				

				</Grid>	
			
			)    		
			
		}
		
	}


	//return a firepoint row 
	getFirePoint = (myKey) => {
		
    	if (this.state.showLoader) {
			return (<Skeleton variant="rounded" width={"100%"} height={40} sx={{marginBottom: 2}} />)    		
    	} else {
    		
			return (
	
				<Grid container columnSpacing={2} rowSpacing={2} paddingBottom={1}>
					<Grid xl={3} xs={6}>
				        <TextField type="number" onChange={this.getFirePointHandler.bind(this, myKey, "start_temp")} label="Start temperature" fullWidth defaultValue={this.state.fCurves[myKey].start_temp} InputProps={{endAdornment: <InputAdornment position="end">°C</InputAdornment>}}/>					
					</Grid>
					<Grid xl={3} xs={6}>
				        <TextField type="number" onChange={this.getFirePointHandler.bind(this, myKey, "end_temp")} label="End temperature" fullWidth value={this.state.fCurves[myKey].end_temp} InputProps={{endAdornment: <InputAdornment position="end">°C</InputAdornment>}}/>					
					</Grid>
					<Grid xl={3} xs={6}>
						<TextField type="number" onChange={this.getFirePointHandler.bind(this, myKey, "increment_deg")} label="Temperature increase" fullWidth defaultValue={this.state.fCurves[myKey].increment_deg} InputProps={{endAdornment: <InputAdornment position="end">°C/hour</InputAdornment>}}/>
					</Grid>
					<Grid xl={2} xs={6}>
				        <TextField type="number" onChange={this.getFirePointHandler.bind(this, myKey, "hold_time")} label="Dwell time" fullWidth defaultValue={this.state.fCurves[myKey].hold_time} InputProps={{endAdornment: <InputAdornment position="end">min.</InputAdornment>}}/>					
					</Grid>
					<Grid xl={1} xs={12} sx={{display:"flex", alignItems: "center", justifyContent: "center"}}>
						<IconButton aria-label="delete" onClick={() => {this.deleteCurve(myKey)}}>
						  <RemoveCircleIcon />
						</IconButton>					
					</Grid>
					<Grid xl={12} xs={12} >
						<Divider />
					</Grid>
				</Grid>	
							
			)     		
   		}		
   		
	}
	
	
	//return submit button
	getSubmit = () => {
		
    	if (this.state.showLoader) {
			return (<Skeleton variant="rounded" width={"100%"} height={40} sx={{marginBottom: 2}} />)    		
    	} else {
	    	return (<Button size="large" variant="contained" sx={{width: "100%"}} onClick={this.doUpload}>Save firing</Button>)
   		}		
	}	
	
	
	
	
	render () { 

		return (

				<main>
				
					<Container maxWidth="md"> 

					    <Box component="form" noValidate autoComplete="off">				    	
	
							{this.getFirePointMeta()}
	
					    	<h4>Temperatures</h4>
							{this.getAllCurves()}
	
							<Grid xl={12} xs={12} marginBottom={6} marginTop={3}>
								<Button variant="outlined" startIcon={<AddCircleIcon />}  onClick={() => this.addCurve()}>Add segment</Button>					
							</Grid>						
	
	
							{this.getSubmit()}
						
						</Box>

					</Container>
					
				</main>					
				
		);
  	}

}

export default AddFiring;