import * as React from 'react';
import { NavLink } from "react-router-dom";
import './Menu.css';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import {Link as RouterLink} from 'react-router-dom';


class Menu extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
			username: null, 
			drawerAdd: false
        };        
    };	


	componentDidUpdate() {

		//check if we have local storage		
		if (typeof(Storage) !== "undefined") {
		  	
		  	//check if we have a token
			if (localStorage.localToken) {

				//get the user data
				this.getLink("/user", "-", true, "user");			
				
			} else {

				//console.log("menu is not logged in");
				
			}
			
		}	
	}   


	
	//check if we should show link
	showLink = (free) => {

		var doWeReturn = false;
		
		//check for login 
		if (free) {
			
			//no token required 
			doWeReturn = true;
			
		} else {

			//we have a hidden item, so check if we're logged in before returning it
			if (localStorage.localToken) {
				doWeReturn = true;
			} 
		}
		
		return (
			doWeReturn
		) 
		
	}
	
	
	getAvatar = () => {

		//check if we have local storage		
		if (typeof(Storage) !== "undefined") {

			if (localStorage.localUserAvatar) {
			
				return (
					<NavLink to={"/user/" + localStorage.localUserId}>
						<Avatar alt={localStorage.localUserName} src={localStorage.localUserAvatar + "?tpl=tiny:box"} sx={{width: 32, height: 32}}/>
					</NavLink>
				)
			}
		
		}
		
	}
	
	//show or hide drawer
	toggleDrawer = () => {

		//boolean toggle
		if (this.state.drawerAdd) {
			this.setState({drawerAdd : false})
		} else {
			this.setState({drawerAdd : true})
		}

		//update our state var
		this.setState(drawerAdd => ({check: !drawerAdd.check}));
	}
	
	
	//calc link
	getLink = (link, desc, free, linkType) => {

		//var with true/false for showing link
		const doWeReturn = this.showLink(free); 

		//if conditions are met, return link
		if (doWeReturn) {
			
			if (linkType === "standard") {
				
				return (<NavLink to={link} className={({ isActive }) => "menuItem" + (isActive ? " menuItemActive" : "")}>{desc}</NavLink>)						
				
			} else if (linkType === "button") {
				
				return (<Button variant="text" className="menuItem" component={RouterLink} to={link} startIcon={<AddCircleIcon />}>{desc}</Button>)
				
			} else if (linkType === "search") {
				
				return (<Button variant="text" className="menuItem" component={RouterLink} to={link} startIcon={<SearchIcon />}>{desc}</Button>)
				
			}   else if (linkType === "user") {

				//we're logged in
				if (localStorage.localToken) {	 
					
					//check if we have a local user name
					if (localStorage.localUserName) {

						return (
							this.getAvatar()
						)

					} 
		
					
				} else {
					
					return (
						<>
							{this.getLink("/signup", "Sign up", true, "standard")}
							{this.getLink("/login", "Login", true, "standard")}
						</>
					)
				}				
				
			} else {
				
			}

						
		} else {
			return null;			
		}
		
	}
	
	
	//return menu items
	getMenu = () => {
		
		return (
			<>
				{this.getLink("/search", "Search", false, "search")}
				{this.getLink("/upload", "Upload", false, "button")}
				{this.getLink("/user", "-", true, "user")}		

			</>
		) 	
	}
	

	render () { 

		return (
		
		  <div className="menuBox">

			<Container maxWidth="xl">
			
				{/* DESKTOP MENU */}			
				<Grid container spacing={0} paddingBottom={0}>
			    	
			    	<Grid xs={12} sm={4} md={6} lg={8} xl={8} border={0} sx={{display: {sm: 'flex', xs: 'none' }}}>
				    	<NavLink to={"/"} className="menuLogo c1text">CLAYCLAY</NavLink>
			    	</Grid>
			    	
			    	<Grid xs={12} sm={8} md={6} lg={4} xl={4} border={0} sx={{display: {sm: 'flex', xs: 'none'}, justifyContent: "flex-end"}}>
					    <nav>
							<Stack direction={{xs: 'row'}} spacing={4} className="menuWrap">
								{this.getMenu()}
							</Stack>      
					    </nav>
			    	</Grid>

			    </Grid>
			    
				{/* MOBILE MENU */}			
				<Grid container spacing={0} paddingBottom={0} margin={0} paddingTop={0}>
			    	
			    	<Grid xs={2} sm={4} md={6} lg={8} xl={8} border={0} sx={{display: {sm: 'none', xs: 'flex' }}}>
				    	<NavLink to={"/"} className="menuLogoMobile">CLAY<br/>CLAY</NavLink>
			    	</Grid>
			    	
			    	<Grid xs={10} sm={8} md={6} lg={4} xl={4} border={0} sx={{display: {sm: 'none', xs: 'flex' }, justifyContent: "flex-end"}}>
					    <nav>
							<Stack direction={{xs: 'row'}} spacing={2} className="menuWrapMobile">
								{this.getMenu()}
							</Stack>      
					    </nav>
			    	</Grid>

			    </Grid>
			    
		    </Container>

		  </div>
		);


  	}

}

export default Menu;