import * as React from 'react';
import axios from 'axios';

import withRouter from './withRouter';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import ImageList from '@mui/material/ImageList';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Cards from './Cards';


class Search extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        	searchQuery: null,
        	searchResponse: null,
      		fGlazes: "",
	      	fClays: "",
	      	weAreLoading: false
        };        

   		this.doSearch = this.doSearch.bind(this);
	
    };


	componentDidMount() {
		
		//let's check if we have a search query 
		this.handleQuery();

		//get the data for the search form fields
		this.prefetchData();
		
		
	}


	handleQuery = () => {

		if (this.props.params.searchQuery) {
			
			var qArr = this.props.params.searchQuery.split(':');
			
			if (qArr[0] === "clay") {
				this.setState({ fClays: qArr[1]});
			} else if (qArr[0] === "glaze") {
				this.setState({ fGlazes: qArr[1]});
			}
			
		}

		
	}


	//API hent data asynkront
	async prefetchData() {
		
		var apiGlazes = "/glazes";		
		var apiClays = "/clays";		

		//check if we have local storage		
		if (typeof(Storage) !== "undefined") {
	  	
			//let's get the data
			try {

				//set our auth token		
				axios.defaults.headers.common['Authorization'] = localStorage.localToken;				

				//set base url
				const client = axios.create({baseURL: process.env.REACT_APP_API});
	
				//get data
				const responseGlazes = await client.get(apiGlazes);
				const responseClays = await client.get(apiClays);
	
				//add our responses to state
				if (responseGlazes.status === 200) {this.setState({ allGlazes: responseGlazes});}
				if (responseClays.status === 200) {this.setState({ allClays: responseClays});}
				
				if (this.state.fGlazes || this.state.fClays) {
					this.doSearch();
				}
				
	
			} catch (error) {
				this.setState({ myError: error});
				console.log(error);
			}
			
		}
		
	}


	getClays = () => {

		if (this.state.allClays) {
			return (this.state.allClays.data.map (item =>(<MenuItem key={item.id} value={item.id}>{item._addonCompanies.company_name} :  {item.product_name}</MenuItem>)))
		} 
	}


	
	getGlazes = () => {

		if (this.state.allGlazes) {
			return (this.state.allGlazes.data.map (item =>(<MenuItem key={item.id} value={item.id}>{item._addonCompanies.company_name} : {item._addonColors.name} {item.glaze_number}</MenuItem>)))
		} 
		
	}


	

	//generate a json search string that we can send to api 
	getSearchExp = (leftQuery, operand, rightQuery) => {

		var myReturn;

		//if user has filled out the right form field, we'll return json
		if (rightQuery) { 
			myReturn = {"statement": {"left": {"tag": "col","operand": leftQuery}, "op": operand, "right": {"operand": rightQuery}}}
		} else {
			myReturn = null;			
		}

		return (myReturn)		
	}


	//hent data asynkront
	async doSearch() {
		
		this.setState({ weAreLoading: true});
		
		//define endpoint
		var apiEndpoint = "/searchPhotos";
		
		const mySearch = this.state.searchQuery;
		const myGlaze = this.state.fGlazes;
		const myClay = this.state.fClays;
		

		//let's get the data
		try {

			//set base url
			const client = axios.create({baseURL: process.env.REACT_APP_API});

			//get data
			const response = await client.get(apiEndpoint, { params: {
				  "search": {
				      "expression": [
						this.getSearchExp("photo.$search1","search", mySearch),
						this.getSearchExp("photo_glazes.glazes_id","=", myGlaze),						
						this.getSearchExp("photo_clays.clays_id","=", myClay)						
				      ]
				  }
			}});

			if (response) { 		
				
				//opdater state var med data
			   	this.setState({ searchResponse: response.data.photos});
				this.setState({ weAreLoading: false});
		   	}

		} catch (error) {
			
			this.setState({ myError: error});
			console.log(error)
		}
		
	}
    

	getPhotos = () => {


		if (this.state.weAreLoading) {

			return (<CircularProgress color="primary" />)
			
		} else {
			
			//if we have some results...		
			if (this.state.searchResponse) {
				
				//let's return a list of available items
				return (
					<>
						<h3>{this.state.searchResponse.items.length} results</h3>	
						<ImageList variant="masonry" gap={4} sx={{columnCount: {xs: '2 !important', sm: '2 !important', md: '3 !important', lg: '4 !important', xl: '4 !important'}}}>
							{this.state.searchResponse.items.map((content) => (
								<Cards data={content} cardType="masonry" key={content.id}/>
							))}
						</ImageList>	
	
			      </>
			    )	
	
			} 			
			
	
		}

	}


	//handle input from search field
    doQuery = (e) => {

		//save our search input to a state var    
    	this.setState({ searchQuery: e.target.value});
    	
   	}
    
	//update state with var from input
   	fHandler = (e) => { 
   		this.setState({[e.target.name]: e.target.value});
   	}
   	    

	render () { 

		return (
			<main>
				<Container maxWidth="xl" sx={{minHeight: "100vh"}}>


					<Grid container columnSpacing={2} rowSpacing={2} marginTop={4}>

						<Grid xs={12} sm={4}>
							<TextField 
								name="fSearch" 
								label="Search term" 
								style={{width:"100%"}} 
								variant="outlined" 
								onChange={this.doQuery}
							/>
						</Grid>
						
						<Grid xs={12} sm={4}>
							<FormControl fullWidth>
								<InputLabel id="fGlazesLabel">Glaze</InputLabel>
								<Select name="fGlazes" value={this.state.fGlazes} label="Glazes" onChange={this.fHandler} >
									<MenuItem value=""><em>Nothing selected</em></MenuItem>								
									{this.getGlazes()}
								</Select>
							</FormControl>						
						</Grid>
						
						<Grid xs={12} sm={4}>
							<FormControl fullWidth>
								<InputLabel id="fClaytypeLabel">Claytype</InputLabel>
								<Select name="fClays" value={this.state.fClays} label="Claytype" onChange={this.fHandler}>
									<MenuItem value=""><em>Nothing selected</em></MenuItem>								
									{this.getClays()}							
								</Select>
							</FormControl>						
						</Grid>
						
						<Grid xs={12} sm={12}>
							<Button variant="contained" className="menuItem" startIcon={<SearchIcon />} onClick={this.doSearch}>Search</Button>
						</Grid>								
						
						<Grid xs={12}>
							{this.getPhotos()}
						</Grid>		

					</Grid>
					
				</Container>
				
			</main>

		)


	}

}

export default withRouter(Search);