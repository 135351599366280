import * as React from 'react';
import axios from 'axios';
import './Cards.css';

import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import Avatar from '@mui/material/Avatar';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {Link as RouterLink} from 'react-router-dom';
import { motion } from "framer-motion";
import Tooltip from '@mui/material/Tooltip';
import CardInfo from './CardInfo';

//icons
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DeleteIcon from '@mui/icons-material/Delete';


class Cards extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
			userMatchCard: null,
			redirect: null, 
			trashed: false, 
			showDrafts: false
        };        
    
   		//bind vores async funktioner
   		this.toggleLike = this.toggleLike.bind(this);
   		this.trashCard = this.trashCard.bind(this);
   		
    };

	
	componentDidMount() {
		
		//check if card was posted by our localUserId
		if (parseInt(this.props.data.user_id) === parseInt(localStorage.localUserId)) {
			this.setState({ userMatchCard: true});
		} else {
			this.setState({ userMatchCard: false});
		}

		//set state from api data
		this.setState({ cardLiked: this.props.data.liked});
		
		//set state to true if we should show drafts		
		this.setState({showDrafts: this.showDrafts()})
		
	}


	//calc post time
	getPostTime = () => {

		var tPosted = new Date(this.props.data.created_at);
		
		return (
			tPosted.toDateString()
		);
		
	}


	//get media
	getCardMedia = () => {
		
		return (
			<CardActionArea sx={{marginBottom:"20px"}} component={RouterLink} to={"/post/" + this.props.data.id}>
				<CardMedia component="img" sx={{minHeight: 400, maxHeight: 400, borderRadius:0}} alt={this.props.data.caption} image={this.props.data.image.url + "?tpl=big:box"}></CardMedia>
			</CardActionArea>
		)
	}	
	
	
	//get caption
	getCaption = () => {
		return (
			<Grid container spacing={0} padding={0} marginBottom={2} paddingLeft={2} className="captionItem">
				<Grid xs={12} className="captionText c1text">
					<Typography noWrap={true} paddingRight={2}>
						{this.props.data.caption}
					</Typography>					
				</Grid>
			</Grid>   		
		
		)
	}		
	
   	//return like button
   	getLikeButton = () => {
   	
  		if (this.state.cardLiked) {
	   		
  			return (
	  			<Tooltip title={this.props.data.like_count + " likes"} placement="left">
	  				<IconButton size="small" onClick={this.toggleLike} color="secondary" className="myIconButton"><FavoriteIcon fontSize="small"/></IconButton>
	  			</Tooltip>
  			)
  			
  		} else {
  			
  			return (
				<IconButton size="small" onClick={this.toggleLike} color="secondary" className="myIconButton"><FavoriteBorderIcon fontSize="small"/></IconButton>	 
  			)
  		}
  		
  		
	}


   	//return trash button
   	getTrashButton = () => {
   		
   		if (this.state.userMatchCard) {
	 		return (
	 			<IconButton size="small" onClick={this.trashCard} color="secondary" className="myIconButton"><DeleteIcon fontSize="small"/></IconButton>)
   		}
	}


	//trash card and reload page
	async trashCard (e) {

		//prevent redirect
		e.preventDefault();

		let text = "Are you sure you want to delete this item? This is not undoable.";

		//trash card if user confirms
		if (window.confirm(text) === true) {

			try {
	
				const client = axios.create({baseURL: process.env.REACT_APP_API});	
	
				const postResponse = await client.delete("/photo/" + this.props.data.id, { params: {
					photo_id: this.props.data.id
				}})
	
				if (postResponse) {
					this.setState({trashed: true});		
				}
	
			} catch (error) {
				console.log(error);
			} 
		} 
		
	}
	
	//return user info
	getUser = () => {
				
		const cardUser = this.props.data._addonUser;
		let userAvatar;
		
		if (this.state.userMatchCard) {
			userAvatar = <Avatar alt={cardUser.handle} src={cardUser.profile_image.url + "?tpl=small:box"} sx={{width: 32, height: 32}} className="myIconButton"/>;
		} else {
			userAvatar = <Avatar alt={cardUser.handle} src={cardUser.profile_image.url + "?tpl=small:box"} sx={{width: 32, height: 32}} className="myIconButton" />;
		}
		

		return (
		
			<motion.div 
				initial={{ opacity: 0}}
			    animate={{ opacity: 1}}
			    transition={{ duration: 0.5 }}						
			>			

				<Grid container padding={1} margin={0} spacing={0} className="userOnCard">
						<Grid xs={9} display="flex" alignItems="center">
						<Tooltip title={cardUser.handle} placement="right">
							{userAvatar}
						</Tooltip>
						</Grid>
						<Grid xs={3} alignItems="center">
							<Stack spacing={1} direction="row" justifyContent="flex-end">
								{this.getLikeButton()}
								{this.getTrashButton()}				
							</Stack>
						</Grid>
	
				</Grid>   		

			</motion.div>			
			
		)
		
	}	



	//hent data asynkront
	async toggleLike(e) { 
		
		//prevent redirect
		e.preventDefault();
		
		//check if we're logged in
		if (localStorage.localToken) {	

			//define api vars
			const client = axios.create({baseURL: process.env.REACT_APP_API});		
			const apiEndpoint = "/likes/" + this.props.data.id;
	
			//toggle like
			if (this.state.cardLiked) {
	
				//remove like
				await client.delete(apiEndpoint).catch(error => {
					this.setState({ myError: error});
				});
	
				this.setState({cardLiked: false});	
					
			} else {
				
				//add like
				await client.post("/likes", {
					photo_id: this.props.data.id
				}).catch(error => {
					this.setState({ myError: error});
				});
		
				this.setState({ cardLiked: true});		
			}

		} else {
			alert("You need to be logged in to perform this action");
		}
    	
	}
	
	
	showDrafts = () => {

		if (this.props.catType !== "user" && this.props.data.draft === true) {
			return false
		} else {
			return true	
		}
		
			
	}
	
	
	render () { 

		//react router redirect handling
		if (this.state.trashed) {
			return null
		} else if (!this.state.trashed && this.state.showDrafts) {

			//normal return
			if (this.props.cardType === "cards") {
				
				//user card
				return (
			
					<Grid xs={12} sm={6} md={4} lg={4} xl={3} key={this.props.data.id}>
						<motion.div
							initial={{ opacity: 0, y:-30 }}
						    animate={{ opacity: 1, y: 0 }}
						    transition={{ duration: 0.5 }}						
						>								
							
							<Card elevation={0} sx={{borderRadius:4, height: "100%"}} >
							    <CardContent sx={{padding:0}}>
							    	{this.getUser()}
							    	{this.getCardMedia()}
							    	{this.getCaption()}
									<CardInfo data={this.props.data} infoType="glazes"/>
									<CardInfo data={this.props.data} infoType="clays"/>
									<CardInfo data={this.props.data} infoType="firings"/>
								</CardContent>
						    </Card>
						</motion.div>    
						    
					</Grid>    
				)
			} else {
				
				//masonry card
				return (

					<motion.div
						initial={{ opacity: 0, y:-30 }}
					    animate={{ opacity: 1, y: 0 }}
					    transition={{ duration: 0.5 }}						
					>					
						<ImageListItem key={this.props.data.id} component={RouterLink} to={"/post/" + this.props.data.id}>
		
							<ImageListItemBar className="cardUserBar"
							  title={this.getUser()}
							  position="top"
							/>
							
							<img
							src={`${this.props.data.image.url}?w=248&fit=crop&auto=format`}
							srcSet={`${this.props.data.image.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
							alt={this.props.data.caption}
							/>

						</ImageListItem>			
						
					</motion.div>	
	
				)			
			}
		
		}
			
  	}

}

export default Cards;