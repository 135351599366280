import * as React from 'react';
import axios from 'axios';
import './User.css';

import Container from '@mui/material/Container';
import CardsModule from './CardsModule';
import withRouter from './withRouter';
import Grid from '@mui/material/Unstable_Grid2';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';


class User extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        	loggedIn: false,
        	loading: false,
        	msg: null,
        	userData: null
        };        
    };	
    
    
    componentDidMount () {

		//get user data
		this.loadUser();

   	}
   	
   	
	//making sure that we refresh our content if url changes
	componentDidUpdate(prevProps) {

	  // Typical usage (don't forget to compare props):
	  if (this.props.params.userId !== prevProps.params.userId) {

		//the url changed, so let's reload our data
		this.loadUser();
	    
	  }
	}   	
    
	//login bruger asynkront
	async loadUser() { 
		
		//set base url
		const client = axios.create({baseURL: process.env.REACT_APP_API});			
		var userId = null;
		
		//handle our states
		this.setState({error: null});
		this.setState({loading: true});
		
		//check if we get a querystring id
		if (this.props.params.userId) {
			userId = this.props.params.userId;
		} else {

			//we don't have querystring with the user id, so let's see if we have some local user
			//check if we have webstorage
			if (typeof(Storage) !== "undefined") {
				
				//set state and userId if we have a local token
				if (localStorage.getItem("localToken")) {
					this.setState({loggedIn: true});
					userId = localStorage.getItem("localUserId")
				} 
				
			}

		}
		
		
		//hent data
		try {
			
			//if we have a user id...
			if (userId) { 			
				
				//and we have a local token
				if (localStorage.localToken) {

					//set our auth token		
					axios.defaults.headers.common['Authorization'] = localStorage.localToken;	
					
					//get our data
					const response = await client.get("/user/" + userId);		
		
					//user data received!
					if (response.status === 200) {
						this.setState({userData: response});
						this.setState({loading: false});
						
					} else {
						this.setState({userData: null});
						this.setState({loading: false});
					}
					
				} else {
					this.setState({msg: "You need to be logged in to see a users profile page"});
				}
				
			} else {
				this.setState({msg: "Sorry, an unknown technical glitch has occured"});
				
			}
	    		
		} catch (error) {
			
			this.setState({error: error});
			console.log("Error loading user: " + error.message);
			
		}

	}


    

	render () { 

		if (this.state.loading) {

			return (
				<Container maxWidth="xl" sx={{minHeight: "100vh"}}>
					<div className="progressWrapper">
						<CircularProgress color="primary" />
					</div>
				</Container>			
			)
			
		} else if (this.state.error) {

			return (
				<Container maxWidth="xl" sx={{minHeight: "100vh"}}>
					<p>Unfortunately we couldn't find any data for this user</p>
					{this.state.error.message}
				</Container>			
			
			)	
			
		} else if (this.state.userData) {
				
				return (
					<Container maxWidth="xl" sx={{minHeight: "100vh"}}>
	
							<Grid container marginTop={8} marginBottom={8}>
								<Grid xs={12} sx={{display:"flex", justifyContent:"center", alignItems: "center"}}>
									<Avatar alt="" src={this.state.userData.data.profile_image.url} sx={{width: 128, height: 128}}/>
								</Grid>
								<Grid xs={12} sx={{display:"flex", flexDirection: "column", justifyContent:"center", alignItems: "center"}}>
									<h2 style={{margin: 8}}>{this.state.userData.data.name}</h2>
								</Grid>							
							</Grid>		
	
							<CardsModule catType="user" catUserId={this.props.params.userId} catTitle="My designs" catLayout="cards" perPage={28}/>
							
					</Container>
				);	
	
				
		} else if (!this.state.userData && !this.state.loggedIn) {
			
			return (
				<Container maxWidth="xl" sx={{minHeight: "100vh"}}>
					<p>{this.state.msg}</p>
				</Container>			
			)
			
		}
		 
	
  	}

}

export default withRouter(User);