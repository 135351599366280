import * as React from 'react';
import axios from 'axios';
import './App.css';
import { Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';

//my modules
import Home from './Home';
import Menu from './Menu';
import User from './User';
import Login from './Login';
import SignUp from './SignUp';
import About from './About';
import AddTabs from './AddTabs';
import EditDesign from './EditDesign';
import Post from './Post';
import Search from './Search';
import Footer from './Footer';

const theme = createTheme({
  palette: {
    primary: {
      main: '#B3863C',
    },
    secondary: {
      light: '#B3863C',
      main: '#422F0F',
      contrastText: '#ffcc00',
    },
    custom: {
      light: '#ffa726',
      main: '#f57c00',
      dark: '#ef6c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
}); 


class App extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        	areWeLoaded: false
        };        
        
        //bind vores async funktioner
   		this.confirmUser = this.confirmUser.bind(this);        
    };	
    
    
	componentDidMount() {

		//check if we have local storage		
		if (typeof(Storage) !== "undefined") {
		  	
		  	//check if we have a token
			if (localStorage.localToken) {

				//set axios token header
				axios.defaults.headers.common['Authorization'] = localStorage.localToken;				

				//load api data
			    this.confirmUser();
			    
			} else {
				
				//we're currently logged out
				//console.log("no token...");
				
				//set our state just to be sure
				this.setState({areWeLoaded: false});
				
			}

		}
	    
	}    
    

	//login bruger asynkront
	async confirmUser() { 
		
		//set base url
		const client = axios.create({baseURL: process.env.REACT_APP_API});			
		
		//hent data
		try {
			
			const response = await client.get("/auth/me");		

			//user data received!
			if (response.status === 200) {
				
				//save user info locally
				if (typeof(Storage) !== "undefined") {
					localStorage.setItem("localUserName", response.data.name);
					localStorage.setItem("localUserEmail", response.data.email);
					localStorage.setItem("localUserId", response.data.id);
					localStorage.setItem("localUserAvatar", response.data.profile_image.url + "?tpl=med:box");
				} else {
				  // Sorry! No Web Storage support..
				}    	
				
				this.setState({areWeLoaded: true});
				
			} else {
				
				console.log(response.status);
				this.setState({areWeLoaded: false});
								
			}

	    		
		} catch (error) {
			
			console.log("Error confirming user: " + error);
			
			//clear local user
			if (typeof(Storage) !== "undefined") {
				localStorage.removeItem("localToken");
				localStorage.removeItem("localUserName");
				localStorage.removeItem("localUserEmail");
				localStorage.removeItem("localUserId");
				localStorage.removeItem("localUserAvatar");
				
				this.setState({areWeLoaded: false});
				
			} else {
			  // Sorry! No Web Storage support..
			}   			
			
		}

	}
	
    
	//render
	render () { 

		return (
		
			<ThemeProvider theme={theme}>
				<div className="App">
	
					<Menu areWeLoaded={this.state.areWeLoaded}/>
		
					<Routes path="/">
						<Route path="/" element={<Home />} />
						<Route path="/login" element={<Login />} />
						<Route path="/signup" element={<SignUp />} />
						<Route path="/user" element={<User />} />
						<Route path="/user/:userId" element={<User />} />
						<Route path="/about" element={<About />} />
						<Route path="/upload" element={<AddTabs />} />
						<Route path="/edit" element={<EditDesign />} />
						<Route path="/search" element={<Search />} />
						<Route path="/search/:searchQuery" element={<Search />} />
						<Route path="/post/:postId" element={<Post />} />
					</Routes>
					
					<Footer />
		
				</div>
			</ThemeProvider>
			  
		);

	}

}

export default App;