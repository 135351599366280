import * as React from 'react';
import { NavLink } from "react-router-dom";

class NotFound extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        };        
    };	



	render () { 

		return (
			<div style={{width: "100%", height: "70vh", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
				<h1>Whoa there!</h1>
				<p>You need to <NavLink to={"/login"}>login</NavLink> first to access this section</p>
			</div>
	
		);
  	}

}

export default NotFound;