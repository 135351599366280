import * as React from 'react';
import './Promo.css';
import Grid from '@mui/material/Unstable_Grid2';
import ceramic1 from './img/promoIntro.png';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {Link as RouterLink} from 'react-router-dom';
import { motion } from "framer-motion"


class Promo extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        	
        };        
    };	



	promoIntro = () => {
		
		
		//if user hasn't hit the button, then...
		if (!localStorage.localToken) {	 	
		
			return (
			
				<Grid container marginBottom={6} className="cPromo1" columnSpacing={2} borderRadius={4}>
	
					<Grid xs={12} md={6} xl={5} padding={{xs: 4, sm: 8}}>	

						<motion.div 
							initial={{ opacity: 0 }}
						    animate={{ opacity: 1 }}
						    transition={{ duration: 0.5 }}						
						>
						<Stack direction="column" spacing={1} marginBottom={4}>
							<h1>CLAYCLAY is a <strong>diary for your ceramics.</strong> </h1>
							<p className="promoTxt1">You can use CLAYCLAY to <strong>share your ceramics</strong> and easily add details about the clay, glazing and firings you've used. Use CLAYCLAY as a <strong>diary</strong> for your ceramics and to <strong>get inspiration</strong> from other ceramists.  </p>
						</Stack>		
						
						<Stack direction="row" spacing={1}>			
							<Button variant="outlined" size="large" component={RouterLink} to="/signup">Sign up</Button>
							<Button variant="outlined" size="large" component={RouterLink} to="/about">More info</Button>
						</Stack>
						
						</motion.div>
						
						

					</Grid>
					
					<Grid xs={12} md={6} xl={7} padding={8} paddingTop={0} display="flex" justifyContent="center" alignItems="center">	
						<motion.div 
							initial={{ opacity: 0, scale: 0.8 }}
						    animate={{ opacity: 1, scale: 1 }}
						    transition={{ duration: 0.5 }}						
						>
							<img src={ceramic1} alt="" className="promoImg1"/>
						</motion.div>
					</Grid>			
									
				</Grid>			
			)

		} 		
	
	}
	

	render () { 

		return (
			this.promoIntro()
		);
  	}

}

export default Promo;