import * as React from 'react';
import Container from '@mui/material/Container';
import CardsModule from './CardsModule';
import Promo from './Promo';

class Home extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        };        
    };	


	render () { 

		return (
			<Container maxWidth="xl" sx={{minHeight: "100vh"}}>
				<main>
					<Promo />			
					<CardsModule catType="latest" catTitle="Latest ceramics" catLayout="masonry" perPage={40} />
				</main>
			</Container>
		);
  	}

}

export default Home;