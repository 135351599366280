import * as React from 'react';
import axios from 'axios';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import { motion } from "framer-motion";

class SignUp extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        	myResponse: null,
        	fName : "",
        	fHandle : "",
        	fPass : "",
        	fEmail : "",
        	fToc: false,
        	imgUrl: "",
			imgData: null,
			fError: null,
			redirect: false
        };
   		
   		//bind vores async funktioner
   		this.addUser = this.addUser.bind(this);
   		this.doLocalLoad = this.doLocalLoad.bind(this);

    };	


	//load local image file into state var ASYNC
	async doLocalLoad (event) {
		
		//get the selected files
		var files = event.target.files; // FileList object
		
		//create an object url from the first file
		const mitImg = document.createElement("img");
		mitImg.src = URL.createObjectURL(files[0]);
		mitImg.onload = function() {
		  URL.revokeObjectURL(this.src);
		}
		
		//update our state with image url and image data
    	this.setState({imgUrl: mitImg.src});
    	this.setState({imgData: event.target.files[0]});
    	
	}    


	//validate our form fields
	doValidate = () => {
		
		//create empty array and reset our state		
		var myError = [];
		this.setState({fError: myError});
		
		//let's validate
		if (!this.state.fHandle) {myError = [...myError, "fHandle"]}
		if (!this.state.fName) {myError = [...myError, "fName"]}
		if (!this.state.fEmail) {myError = [...myError, "fEmail"]}
		if (!this.state.fPass) {myError = [...myError, "fPass"]}
		
		//if we have one or more errors, let's update our state
		if (myError.length !== 0) {
			this.setState({fError: myError});
		} else {
			
			//let's try to sign up
			this.addUser();
		}

	}
	
	
	
	getValidation = (myId) => {
		
		//we have some errors
		if (this.state.fError) {

			//return true if we have myId in the error array			
			if (this.state.fError.indexOf(myId) > -1) {return true} else {return false}

		} else {

			//we have no errors in our array, so let's return false
			return false

		}	
		
	}	
	

	//tilføj bruger asynkront
	async addUser() { 
		
		//bind our this to be able to use setState in postResponse
		var that = this;		
		
		const tmpfHandle = this.state.fHandle;
		const tmpfName = this.state.fName;
		const tmpfEmail = this.state.fEmail;
		const tmpfPass = this.state.fPass;
		const tmpfToc = this.state.fToc;

		if (this.state.imgData) {

			if (this.state.fToc) {
				
				var reader = new FileReader();
				reader.readAsDataURL(this.state.imgData); 
				reader.onloadend = async function() {
					
					//we now have our base64 data
					var base64data = reader.result;   
					
					//set base url
					const client = axios.create({baseURL: process.env.REACT_APP_API});			
					
					try {
						
						console.log("Attempting image upload...");
						
						const imgUploadResponse = await client.post("/upload/image", {
							"content": base64data
						});
						
						
						if (imgUploadResponse) {
							
							console.log("Image was uploaded");
							
							//login
							const response = await client.post("/auth/signup", { 
								name: tmpfName,
								email: tmpfEmail,
								password: tmpfPass,
								handle: tmpfHandle,
								agree_to_toc: tmpfToc,
								profile_image: imgUploadResponse.data
							}).catch(error => {
								alert("User couldn't be created - error message: " + error.response.data.message);
							});
							

							console.log(response);

							
							if (response.status === 200) {  

								if (typeof(Storage) !== "undefined") {
									
									//let's save data to local storage
									localStorage.setItem("localToken", response.data.authToken);
	
									//let's inform the user
									that.setState({redirect: true});	
									
									console.log("lets redirect!");
									
								} else {
								  	// Sorry! No Web Storage support..
								}	
							
							} else {
								console.log("An error occured : " + response.status);								
							}			
							
						}
								
					} catch (error) {

						//console.log("FEJL");
					}
							             
				}
				
			} else {
				alert("You need to approve the guidelines in order to sign up");				
			}
			
			
		
		} else {
			alert("You need to add a profile image to sign up");
		}
		
	}
	
	//update state with form input values
	updateState = (event) => {
		
		//check if it's a checkbox or an input field and then update state accordingly 
		if (event.target.name === "fToc") {		
    		this.setState({[event.target.name] : event.target.checked});
    	} else {
    		this.setState({[event.target.name] : event.target.value});
    	}
    	
	}

	render () { 

		//react router redirect handling
		if (this.state.redirect) {
		
			return (
				<main>
					<Container maxWidth="xs" sx={{minHeight: "100vh"}}> 
	
						<motion.div 
							initial={{ opacity: 0, y:-30 }}
						    animate={{ opacity: 1, y: 0 }}
						    transition={{ duration: 0.5 }}						
						>						
	
							<h1>Welcome to CLAYCLAY!</h1>
							<p>Your user account has now been created.</p>
							<Button size="large" variant="contained" component={RouterLink} to="/login">Continue to login</Button>
							
						</motion.div>
	
					</Container>
				</main>			
			)				
		
		} else {
			
			return (
				<main>
					<Container maxWidth="xs" sx={{minHeight: "100vh"}}> 
						<h1>New user</h1>
	
						<motion.div 
							initial={{ opacity: 0, y:-30 }}
						    animate={{ opacity: 1, y: 0 }}
						    transition={{ duration: 0.5 }}						
						>						
	
							<Stack direction="column" spacing={2}>
		
								<Button aria-label="Upload picture" component="label" variant="outlined" onChange={this.doLocalLoad}>
									<Avatar alt={this.state.fHandle} src={this.state.imgUrl} sx={{width: 48, height: 48, margin: 2}}/>
									<input accept="image/jpg, image/jpeg, image/png, image/gif" type="file" required name="fFile"/>
								</Button>	
		
								<TextField label="Full name" type="input" name="fName" onChange={this.updateState} required error={this.getValidation("fName")} />	
								<TextField label="Username" type="input" name="fHandle" onChange={this.updateState} required error={this.getValidation("fHandle")} />	
								<TextField label="Email" type="email" name="fEmail" onChange={this.updateState} required error={this.getValidation("fEmail")} />	
								<TextField label="Password"  name="fPass" type="password" helperText="Minimum: 8 characters, small/capital letters, at least one special character. Please don't reuse your old password - make a unique new one" onChange={this.updateState} required error={this.getValidation("fPass")}/>	

								<Button size="large" variant="contained" onClick={this.doValidate}>Sign up</Button>
								
								<FormGroup>
								  <FormControlLabel control={<Checkbox name="fToc" onClick={this.updateState} />} label="Yup, I agree with the Guidelines" />
								  <Link component={RouterLink} to="/about" target="_blank" sx={{marginLeft: "33px", fontSize: "0.8em"}}>Read our Guidelines here</Link> 
								</FormGroup>
								
		
							</Stack>
							
						</motion.div>
	
					</Container>
				</main>			
			)			
		
		
		}



  	}

}

export default SignUp;