import * as React from 'react';
import axios from 'axios';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import './AddGlaze.css';

class AddGlaze extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
      		fGlazeNumber: "",
      		fTransparency: "",
      		fProductType: "",
      		fColor: "",
      		fShine: "",
      		fCompany: "",
      		fClaytype: "",
      		fError: null
        };        
        
   		this.uploadData = this.uploadData.bind(this);
        
        
    };	
    

	//mount our component
	componentDidMount() {
		this.loadData();
	}       
	
	//update state with var from input
   	fHandler = (e) => { 
   		this.setState({[e.target.name]: e.target.value});
   	}


	//update state with var from select
   	handleChipClick = (e) => { 
   		
   		if (this.state[e.target.parentElement.id] === "primary") {
	   		this.setState({[e.target.parentElement.id]: "default"});
  		} else {
	   		this.setState({[e.target.parentElement.id]: "primary"});
  		}

   	}


	//API hent data asynkront
	async loadData() { 
		
		var apiCompanies = "/companies";		
		var apiColors = "/colors";		
		var apiClaytypes = "/claytypes";		

		//check if we have local storage		
		if (typeof(Storage) !== "undefined") {
	  	
			//let's get the data
			try {

				//set our auth token		
				axios.defaults.headers.common['Authorization'] = localStorage.localToken;				

				//set base url
				const client = axios.create({baseURL: process.env.REACT_APP_API});
	
				//get data
				const responseCompanies = await client.get(apiCompanies);
				const responseColors = await client.get(apiColors);
				const responseClaytypes = await client.get(apiClaytypes);
	
				//add our responses to state
				if (responseCompanies.status === 200) {this.setState({ allCompanies: responseCompanies});}
				if (responseColors.status === 200) {this.setState({ allColors: responseColors});}
				if (responseClaytypes.status === 200) {this.setState({ allClaytypes: responseClaytypes});}
	
			} catch (error) {
				this.setState({ myError: error});
				console.log(error);
			}
			
		}
		
	}



	//API hent data asynkront
	async uploadData() { 
		

		//let's validate
		var myError = [];
		if (!this.state.fTransparency) {myError = [...myError, "fTransparency"]}
		if (!this.state.fCompany) {myError = [...myError, "fCompany"]}
		if (!this.state.fProductType) {myError = [...myError, "fProductType"]}
		if (!this.state.fColor) {myError = [...myError, "fColor"]}
		if (!this.state.fShine) {myError = [...myError, "fShine"]}
		if (!this.state.fClaytype) {myError = [...myError, "fClaytype"]}
		if (!this.state.fGlazeNumber) {myError = [...myError, "fGlazeNumber"]}
		
		//if we have one or more errors, let's update our state
		if (myError.length !== 0) {this.setState({fError: myError});}

		//get our foodsafe chip and save into var
		var minFoodSafe = false;
		if (this.state.cFoodSafe === "primary") {minFoodSafe = true;}

		//get our other chip values and add them to an empty array	
		var mitArray = [];
		if (this.state.cGlimmer === "primary") {mitArray = [...mitArray, "glitter"]}
		if (this.state.cMeleret === "primary") {mitArray = [...mitArray, "mottled"]}
		if (this.state.cKrystal === "primary") {mitArray = [...mitArray, "crystal"]}
		if (this.state.cKravleglasur === "primary") {mitArray = [...mitArray, "crawlglaze"]}
		if (this.state.cPletter === "primary") {mitArray = [...mitArray, "spots"]}
		if (this.state.cPrikker === "primary") {mitArray = [...mitArray, "dots"]}
		if (this.state.cNistret === "primary") {mitArray = [...mitArray, "nibbled"]}
		if (this.state.cLava === "primary") {mitArray = [...mitArray, "lava"]}
		if (this.state.cMetallic === "primary") {mitArray = [...mitArray, "metallic"]}
		if (this.state.cKrakeleret === "primary") {mitArray = [...mitArray, "crackle"]}
		if (this.state.cDyb === "primary") {mitArray = [...mitArray, "deep"]}
		if (this.state.cGuldeffekt === "primary") {mitArray = [...mitArray, "gold effect"]}

		
		//if we have 0 validation errors...
		if (myError.length === 0) {

			//and if we're logged in...
			if (typeof(Storage) !== "undefined") {
		  	
				//let's post the data
				try {
					
					//make vars with data 
					const apiEndpoint = "/glazes"				
					const myDataToApi = {
						companies_id: this.state.fCompany,
						glaze_number: this.state.fGlazeNumber,
						product_type: this.state.fProductType,
						claytypes_id: this.state.fClaytype,
						colors_id: this.state.fColor,
						shine: this.state.fShine,
						transparency: this.state.fTransparency,
						foodsafe: minFoodSafe,
						effekt: mitArray,
						user_id: localStorage.getItem('localUserId')
					}
					
					//set our auth token		
					axios.defaults.headers.common['Authorization'] = localStorage.localToken;				
	
					//set base url
					const client = axios.create({baseURL: process.env.REACT_APP_API});
		
					//get data
					const response = await client.post(apiEndpoint, myDataToApi);
		
					if (response.status === 200) {
						
						//let's clear our glaze number, so user can't submit form again without revalidating
						this.setState({fGlazeNumber: ""});
						
						//inform the user
						alert("Your glaze has been added to our database!");
						
				   	} else {
						console.log("Sorry, info wasn't submitted due to unknown error");
						console.log(response);
				   	}
	
		
				} catch (error) {
					//this.setState({ myError: error});
					console.log(error);
				}			
				
	
			} else {
				alert("You need to be logged in to create a firing");				
			}
			
			
		}
		
	}	



	getClaytypes = () => {

		if (this.state.allClaytypes) {
			return (this.state.allClaytypes.data.map (item =>(<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)))
		} 
	}



	getColors = () => {
		
		//<span className="colorDot" style={{backgroundColor: ("#" + item.color_code)}}></span>

		if (this.state.allColors) {
			return (this.state.allColors.data.map (item =>(
				<MenuItem key={item.id} value={item.id}>
					<ListItemText primary={item.name} />
				</MenuItem>
			)))
		} 		
	}	

	
	getCompanies = () => {

		if (this.state.allCompanies) {
			return (this.state.allCompanies.data.map (item =>(<MenuItem key={item.id} value={item.id}>{item.company_name}</MenuItem>)))
		} 
		
	}
	
	
	getValidation = (myId) => {
		
		//we have some errors
		if (this.state.fError) {

			//return true if we have myId in the error array			
			if (this.state.fError.indexOf(myId) > -1) {
				return true			
			} else {
				return false				
			}
			

		} else {

			//we have no errors in our array, so let's return false
			return false

		}	
		
	}



	render () { 

		return (
			<main>
				<Container maxWidth="md">
				
					<Stack spacing={4}>
						<div>
							<h3>Add new glaze</h3>
							<p>If there's a glaze you can't find in our database, you can add it here. Please take care to ensure that the data you provide is accurate. </p>
						</div>
					
					
						<TextField 
							required 
							name="fGlazeNumber" 
							label="Glaze number / product number" 
							error={this.getValidation("fGlazeNumber")} 
							style={{width:"100%"}} 
							multiline 
							variant="outlined" 
							onChange={this.fHandler}
							helperText="Find the glaze/product number on the manufacturers website"
						/>

						<FormControl required fullWidth>
							<InputLabel id="fCompanyLabel">Manufacturer</InputLabel>
							<Select name="fCompany" value={this.state.fCompany} label="Manufacturer" onChange={this.fHandler}  error={this.getValidation("fCompany")}>
								{this.getCompanies()}
							</Select>
							<FormHelperText>If there's a manufacturer you can't find, please email user@clayclay.net with the manufacturers name and website</FormHelperText>
						</FormControl>	

						<FormControl required fullWidth>
							<InputLabel id="fProductTypeLabel">Glazing type</InputLabel>
							<Select name="fProductType" value={this.state.fProductType} label="Glazingtype" onChange={this.fHandler} error={this.getValidation("fProductType")}>
								<MenuItem value="dip">Dipping</MenuItem>
								<MenuItem value="brush">Brush</MenuItem>
								<MenuItem value="raku">Raku</MenuItem>
								<MenuItem value="slip">Slip</MenuItem>
								<MenuItem value="universal">Universal</MenuItem>
								<MenuItem value="deco">Deco</MenuItem>
								<MenuItem value="underglaze">Underglazing</MenuItem>
							</Select>
						</FormControl>	

						<FormControl required fullWidth>
							<InputLabel id="fColorLabel">Color</InputLabel>
							<Select name="fColor" value={this.state.fColor} label="Farve" onChange={this.fHandler} error={this.getValidation("fColor")}>
								{this.getColors()}
							</Select>
						</FormControl>	

						<FormControl required fullWidth>
							<InputLabel id="fShineLabel">Shine</InputLabel>
							<Select name="fShine" value={this.state.fShine} label="Glans" onChange={this.fHandler} error={this.getValidation("fShine")}>
								<MenuItem value="Dull">Dull</MenuItem>
								<MenuItem value="Silk Gloss">Silk Gloss</MenuItem>
								<MenuItem value="Semi Glossy">Semi-Shine</MenuItem>
								<MenuItem value="Glossy">Glossy</MenuItem>
							</Select>
						</FormControl>	

						<FormControl required fullWidth>
							<InputLabel id="fTransparency">Transparency</InputLabel>
							<Select name="fTransparency" value={this.state.fTransparency} label="Transparens" onChange={this.fHandler} error={this.getValidation("fTransparency")}>
								<MenuItem value="Opaque">Opaque</MenuItem>
								<MenuItem value="Semi-transparent">Semi-transparent</MenuItem>
								<MenuItem value="Transparent">Transparent</MenuItem>
							</Select>
						</FormControl>	

						<FormControl required fullWidth>
							<InputLabel id="fClaytypeLabel">Primarily used for</InputLabel>
							<Select name="fClaytype" value={this.state.fClaytype} label="Primaryly used for" onChange={this.fHandler} error={this.getValidation("fClaytype")}>
								{this.getClaytypes()}							
							</Select>
						</FormControl>	

						<Grid container>
							<Chip name="cFoodSafe" id="cFoodSafe" icon={<RestaurantIcon />} label="Approved for food" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cFoodSafe} />
							<Chip id="cEffekt" label="Effect" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cEffekt} />
							<Chip id="cGlimmer" label="Glitter" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cGlimmer} />
							<Chip id="cMeleret" label="Mottled" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cMeleret} />
							<Chip id="cKrystal" label="Crystal" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cKrystal} />
							<Chip id="cKravleglasur" label="Crawlglaze" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cKravleglasur} />
							<Chip id="cPletter" label="Spots" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cPletter} />
							<Chip id="cPrikker" label="Dots" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cPrikker} />
							<Chip id="cNistret" label="Nibbled" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cNistret} />
							<Chip id="cLava" label="Lava" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cLava} />
							<Chip id="cMetallic" label="Metallic" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cMetallic} />
							<Chip id="cKrakeleret" label="Crackle" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cKrakeleret} />
							<Chip id="cDyb" label="Dip" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cDyb} />
							<Chip id="cGuldeffekt" label="Gold effect" onClick={this.handleChipClick} className="chipEffekt" color={this.state.cGuldeffekt} />
						</Grid>	

						<Button size="large" variant="contained" sx={{width: "100%"}} onClick={this.uploadData}>Save glaze</Button>


					</Stack>

			
				</Container>
			</main>				
		)
  	}

}

export default AddGlaze;