import * as React from 'react';
import axios from 'axios';

import Grid from '@mui/material/Unstable_Grid2';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';

class Comments extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        	myComment: ""
        };        

   		//bind vores async funktioner
   		this.loadData = this.loadData.bind(this);
   		this.postData = this.postData.bind(this);
    
	
    };


	componentDidMount() {
		
		//load our data
		this.loadData();
		
	}

	
	//making sure that we refresh our content if our data changes
	componentDidUpdate(prevProps) {

		// Typical usage (don't forget to compare props):
		if (this.props.data !== prevProps.data) {
			this.loadData();
		}

	}


	//hent data asynkront
	async loadData() {
		
		//let's reset our form field value
		this.setState({ myComment: ""});
		
		//define endpoint
		var apiEndpoint = "/comments/" + this.props.data.id;

		//let's get the data
		try {

			//set base url
			const client = axios.create({baseURL: process.env.REACT_APP_API});

			//get data
			const responsePost = await client.get(apiEndpoint, { params: {
				photo_id: this.props.data.id
			}});

			if (responsePost) { 			
				
				//opdater state var med data
			   	this.setState({ myPost: responsePost.data});
			   	
		   	}

		} catch (error) {
			
			this.setState({ myError: error});
			console.log(error)
		}
		
	}
    
    
    //submit data handler
    postDataHandler = (event) => {
		this.setState({ myComment: event.target.value});
   	} 
   	
   	
	//hent data asynkront
	async postData() { 
		
		const myComment = this.state.myComment;
		
		//define endpoint
		var apiEndpoint = "/comment";

		//let's try to post our comment
		try {

			//set base url
			const client = axios.create({baseURL: process.env.REACT_APP_API});
			
			//post comment to api
			const response = await client.post(apiEndpoint, { 
				comment: myComment,
				photo_id: this.props.data.id
			}).catch(error => {
				this.setState({ myError: error});
			});			
			
			if (response) { 	
			   	this.loadData();
		   	}

		} catch (error) {
			console.log(error)
		}
		
	}    
    
    
    //loop available comments
    listComments = () => {

		if (this.state.myPost) {
			
			const myPost = this.state.myPost;

			if (myPost.itemsTotal > 0) {
				
				return (
	
					myPost.items.map (content =>(
						this.showComment(content)
					))			
				
				) 
			} 
			
		}
		   	
   	}
   	
   	
   	//return a trash button if comment is made by logged in user
   	getTrashButton = (myContent) => {
   		
		if (typeof(Storage) !== "undefined") {
		
			if (localStorage.localToken) {

		   		const commentUser = parseInt(myContent.user.id);
				const localUser = parseInt(localStorage.getItem("localUserId"));
				
				if (commentUser === localUser) {

			   		return (
			   			<IconButton aria-label="Delete comment" size="small" onClick={() => {this.deleteComment(myContent.id)}}><DeleteIcon /></IconButton>
			   		)					

				}
			
			} 
		
		}
  	
  	}
  	

	//delete a comment
	async deleteComment (commentId) {

		//define endpoint
		var apiEndpoint = "/comment/" + commentId;

		//let's get the data
		try {

			//set base url
			const client = axios.create({baseURL: process.env.REACT_APP_API});

			//get data
			const responsePost = await client.delete(apiEndpoint, { params: {
				comments_id: commentId
			}});

			//do stuff if we're successfull
			if (responsePost.status === 200) { 	
				this.loadData();
		   	}

		} catch (error) {
			
			this.setState({ myError: error});
			console.log(error)
		}
		
	}  	
   	
	

	//show a single comment	
	showComment = (myContent) => {
		
		return (
			<Grid container key={myContent.id}>		
				<Grid xs={1} padding={0} margin={0}><Avatar alt={myContent.user.handle} src={myContent.user.profile_image.url + "?tpl=small:box"} sx={{width: 40, height: 40}}/></Grid>
				<Grid xs={11} className="cWhite" padding={2} margin={0} sx={{borderRadius:"8px"}}>
					
					<Grid container padding={0} margin={0}>
						<Grid xs={11} padding={0} margin={0}>{myContent.comment}</Grid>
						<Grid xs={1} padding={0} margin={0}>{this.getTrashButton(myContent)}</Grid>
					</Grid>
				</Grid>
			</Grid>	
		)
		
	}
	
	
	
	//write a new comment
	addComment = () => {
		
		
		//check if we have local storage		
		if (typeof(Storage) !== "undefined") {
		
			if (localStorage.localToken) {
		
				//get users avatar img
				const userImg = localStorage.getItem("localUserAvatar");
			
				return (
					<Grid container>		
						<Grid xs={1} padding={0} margin={0}><Avatar alt={this.props.data._addonUser.handle} src={userImg}/></Grid>
						<Grid xs={11} sx={{borderRadius:"20px"}} padding={0}>
							<TextField 
								id="outlined-basic" 
								label="Add comment" 
								variant="outlined" 
								value={this.state.myComment}
								onChange={this.postDataHandler}
								multiline 
								maxRows={4} 
								fullWidth
								InputProps={{ 
									endAdornment: 
						              <InputAdornment position="end">
						                <IconButton aria-label="Post your comment" onClick={this.postData}><SendIcon /></IconButton>
						              </InputAdornment>
					            }}
					            
							/>
						</Grid>
						
					</Grid>			
				)	
				
			} else {
			
				return (
					<p>You need to be logged in to add a comment</p>
				)
			}
		
		} else {
			return (null)			
		}	
		
		
			

	}
	
	
	
	render () { 


		return (
			<>
				{this.listComments()}
				{this.addComment()}
			</>
		)


	}

}

export default Comments;