import * as React from 'react';
import axios from 'axios';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Unstable_Grid2';


class InputFilter extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
        	myLoadState : true
        };        
        
    };	
    
    

	//do stuff on mount
	componentDidMount() {

		if (localStorage.localUserId) {

			//load our data from the api
			this.getData();	    
			
		} else {
			console.log("No user id found");		
		}
	    
	}
	
	//making sure that we refresh our data if random prop changes
	componentDidUpdate(prevProps) {

	  // Typical usage (don't forget to compare props):
	  if (this.props.randomProp !== prevProps.randomProp) {

		//let's reload our data
		if (localStorage.localUserId) {

			//load our data from the api
			this.getData();	    
			
		} 
	    
	  }
	}		
	
    
	//get api data
	async getData() { 
		
		//set base url
		const client = axios.create({baseURL: process.env.REACT_APP_API});			
		
		//hent data
		try {
			const allInventory = await client.get(this.props.endpoint);		
			this.setState({allInventory: allInventory});	
			this.setState({myLoadState: false});
			
		} catch (error) {
			this.setState({ myError: error});
			console.log(error.message);
		}

	}    
    
    
    //return a combobox with API items loaded
    getFilter = () => {

		if (this.state.allInventory) {
			
			//let's get all the options and sort them
			var myItems = this.loopData();
			myItems = myItems.sort((a, b) => (a > b ? -1 : 1))

			//define an empty array to hold a selection list from a prop 
			var myInputValues = [];
			
			//if we get a selection, let's make a new array 
			if (this.props.inputSelection) {
				myInputValues = this.props.inputSelection.map (function (item) {
					return (myItems[item])
				})	
			}


			return (
	
			    <Autocomplete
			      limitTags={2}
			      multiple
			      required
			      selectOnFocus 
			      clearOnBlur 
			      handleHomeEndKeys
			      key={this.props.inputId}
			      options={myItems}
			      groupBy={(option) => option.title}
			      getOptionLabel={(option) => option.label}
			      sx={{ width: "100%" }}
			      renderInput={(params) => 
			      	<TextField {...params} label={this.props.title} />
			      }
			      renderOption={(props, option) => (
				      <Grid container spacing={0} {...props} key={option.value}>
				        <Grid xs={12} sm={6}>{option.label}</Grid>
				        <Grid xs={12} sm={6}>{option.secondary}</Grid>
				      </Grid>			      
			        
			      )}
			      onChange={this.myEventHandler}
			      defaultValue={myInputValues}
			    />
			
			
			)  
		}		  	
    	

   	}
   	
   	//call function on parent 
   	myEventHandler = (e, myVals) => {
   		this.props.onInputUpdate(this.props.endpoint, myVals);
	}
   	
   	
   	
	//map and return available clays, glazes, etc
	loopData = () => {

		//check if we have api data and map using the correct loop pattern
		if (this.state.allInventory) {
			
			if (this.props.endpoint === "/clays") {
				
				return (
				
					this.state.allInventory.data.map (item =>({
						label: item.product_name, 
						secondary: item.product_number,
						value: item.id, 
						title: item._addonCompanies.company_name 
					})
				))				

			} else if (this.props.endpoint === "/glazes") {
				
				return (
					
					this.state.allInventory.data.map (item =>({
						label: item._addonColors.name, 
						secondary: item.glaze_number + ", " + item.shine + ", " + item.transparency + ", " + item.effekt,
						value: item.id, 
						title: item._addonCompanies.company_name 
					})
				))				

			} else if (this.props.endpoint === "/firingsv2") {

				return (this.state.allInventory.data.map (item =>({
						secondary: "",
						label: item.title, 
						value: item.id 
					})
				))				

			}			

		}		
            	
	}   	
    
    
	render () { 

		if (this.state.myLoadState) {
			return (
				<Skeleton variant="rounded" width={"100%"} height={50} />		
			)
		} else {
			return (
				<>{this.getFilter()}</>
			);
		}	
			
  	}

}

export default InputFilter;